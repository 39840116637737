import { keyframes } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Button } from '@metacrm/metacrm-material-ui/dist/Button';
import Box from '@mui/material/Box';

import { yellowBlock, blueBlock } from 'SRC/assets/images';
import isStyledPropsValid from 'SRC/utils/isStyledPropsValid';

const float = keyframes({
	'0%': {
		opacity: 1,
		transform: 'translateY(0)',
	},
	'100%': {
		transform: 'translateY(-80px)',
	},
});

const growAndFade = keyframes({
	'0%': {
		opacity: 0.5,
		transform: 'scale(0.7)',
	},
	'100%': {
		opacity: 0,
		transform: 'scale(2)',
	},
});

export const scrollBarMixin = (theme) => ({
	'&::-webkit-scrollbar': {
		width: '3px',
		height: '3px',
	},
	'&::-webkit-scrollbar-track': {
		background: '#151A2F',
	},
	'&::-webkit-scrollbar-thumb': {
		background: '#43957C',
	},
	'&::-webkit-scrollbar-thumb:hover': {
		background: '#555',
	},
});

const monsterBackgroundImage = {
	Wojak: require('SRC/assets/images/city.png'),
	Bonk: require('SRC/assets/images/woods.png'),
};

export const StyledHomeContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, monsterBackground }) => ({
	// width: '100%',
	flexDirection: 'column',
	display: 'flex',
	height: '100%',
	// overflow: 'auto',
	background: 'linear-gradient(180deg, #0F1221 0%, #151A2F 82.5%, #43957C 100%)',
	paddingBottom: '30px',
}));

export const StyledHomeTopBar = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	background: '#82DA13',
	fontSize: '22px',
	boxShadow: `inset 0px 4px #c1ed1d, inset 0px -4px #43c70a`,
	display: 'flex',
	padding: '11px 18px 11px 13px',
	alignItems: 'center',
	justifyContent: 'space-between',
}));

export const StyledInfoBlock = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, blockColor = 'yellow' }) => {
	const bgColor = blockColor === 'yellow' ? yellowBlock : blueBlock;
	return {
		marginRight: '5px',
		backgroundImage: `url(${bgColor})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 100%',
		height: '30px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '8px 10px',
	};
});

export const StyledRipple = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	position: 'absolute',
	height: '100%',
	width: `100%`,
	top: '0',
	zIndex: 1,
	borderRadius: '50%',
	background: '#D2122A',
	animation: `${growAndFade} 0.7s ease-out`,
	opacity: 0,
}));

export const StyledFloatNumber = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, x, y }) => ({
	position: 'absolute',
	fontWeight: 'bold',
	opacity: 0,
	fontSize: '36px',
	top: `${y - 40}px`,
	left: `${x - 25}px`,
	animation: `${float} 1s ease-out`,
	zIndex: 2,
	whiteSpace: 'nowrap',
}));

export const StyledBottomInfoArea = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	width: '100%',
	// background: '#132A5C',
	borderRadius: '10px 10px 0 0',
	display: 'flex',
	flexDirection: 'column',
	position: 'relative',
}));

export const StyledBossListContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '5px 14px',
	// borderBottom: '2px solid #000',
	// borderLeft: '2px solid #000',
	// boxShadow: `inset -3px 2px #2252DA`,
	overflowY: 'auto',
	...scrollBarMixin(theme),
}));

export const StyledButtonAreaContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, sx }) => ({
	padding: '4px 10px',
	// background: `linear-gradient(180deg, #1C3877 0%,  #0F1221 100%)`,
	flex: 1,
	// overflow: 'auto',
	display: 'flex',
	justifyContent: 'space-between',
	...scrollBarMixin(theme),
}));

const noMoneyFloat = keyframes({
	'0%': {
		opacity: 1,
		transform: 'translate(-50%,0)',
	},
	'100%': {
		opacity: 0,
		transform: 'translate(-50%,-80px)',
	},
});

export const StyledShowMoneyAlert = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, routeName }) => ({
	position: 'absolute',
	backgroundColor: 'rgba(0, 0, 0, 0.4)',
	color: 'white',
	padding: '5px 10px',
	borderRadius: '10px',
	zIndex: 5,
	bottom: '215px',
	left: '50%',
	opacity: 0,
	transform: 'translate(-50%,0)',
	animation: `${noMoneyFloat} 2s ease-out`,
}));

export const StyledHomeGameArea = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, monsterBackground }) => ({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	// backgroundImage: `url(${monsterBackground})`,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center center',
	position: 'relative',
}));

export const StyledHomeTopArea = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, monsterBackground, topHasSomething }) => ({
	position: 'absolute',
	maxWidth: '450px',
	zIndex: 3,
	top: topHasSomething ? '70px' : '10px',
}));
