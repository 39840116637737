import React from 'react';

import PropTypes from 'prop-types';

import { StyledDialogBtn as StyledStyledDialogBtn } from 'SRC/Dialog.styles';

function StyledDialogBtn({ onClick, isLoading, children, ...props }) {
	const handleClick = () => (isLoading ? null : onClick());
	return (
		<StyledStyledDialogBtn
			className='strokeText thick'
			data-storke={children}
			onClick={handleClick}
			isLoading={isLoading}
			{...props}
		>
			{children}
		</StyledStyledDialogBtn>
	);
}

StyledDialogBtn.propTypes = {
	onClick: PropTypes.func,
	isLoading: PropTypes.bool,
	children: PropTypes.node,
};

export default StyledDialogBtn;
