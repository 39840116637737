import request from './base/request';

export function fetchLoginInfo({
	telegramId,
	username,
	firstName,
	lastName,
	languageCode,
	referralCode,
	initData,
}) {
	const config = {
		data: {
			telegramId,
			username,
			firstName,
			lastName,
			languageCode,
			referralCode,
			initData,
		},
	};
	return request({
		url: '/api/users/login',
		method: 'post',
		config,
	});
}

export function fetchReferralCode({ telegramId, token }) {
	const config = {
		params: {
			telegramId,
		},
		headers: {
			authorization: `Bearer ${token}`,
		},
	};
	return request({
		url: '/api/referral/code',
		method: 'get',
		config,
	});
}

export function fetchProfile() {
	const config = {
		params: {
			telegramId: 56789,
			username: 'unayung',
		},
	};
	return request({
		url: '/api/users/profile',
		method: 'get',
		config,
	});
}

export function postReset() {
	const config = {
		data: {},
	};
	return request({
		url: '/api/game/resetMe',
		method: 'post',
		config,
	});
}

export function fetchLevelMapping({ token }) {
	const config = {
		params: {
			token,
		},
	};
	return request({
		url: '/api/game/mappings',
		method: 'get',
		config,
	});
}

export default {
	fetchLoginInfo,
	fetchProfile,
	postReset,
	fetchLevelMapping,
};
