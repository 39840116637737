import styled from '@emotion/styled/macro';
import { Button } from '@metacrm/metacrm-material-ui/dist/Button';
import Box from '@mui/material/Box';

import isStyledPropsValid from 'SRC/utils/isStyledPropsValid';

export const StyledLoadingPageContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	backgroundImage: `url(${require('SRC/assets/images/loading-page-blue.png')})`,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center center',
	height: '100dvh',
	display: 'flex',
	flexDirection: 'column-reverse',
	color: theme.customColors.white,
}));
