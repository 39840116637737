/* eslint-disable no-useless-catch */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { find, get } from 'lodash-es';
import moment from 'moment';

import { postAttack, postMonsterKill, postSkillUpdate } from 'SRC/api/game';
import { fetchLoginInfo, fetchLevelMapping, fetchReferralCode } from 'SRC/api/profile';
import sendOfflinePostRequestsToServer, {
	saveAllyRequest,
	sendAllyRequestsToServer,
} from 'SRC/utils/apiRecallFromDB';
import { getStorage, setStorage } from 'SRC/utils/storage';

const initialState = {
	coins: 0,
	ATK: 0,
	energy: 0,
	maxEnergy: 0,
	clicks: [],
	energyToAddAnySecond: 3,
	isLoading: false,
	level: 1,
	monsterList: [],
	skillList: [],
	currentMonster: {},
	nextMonster: {},
	monsterTotalBlood: 0,
	monsterRemainedHealth: 0,
	totalDamage: 0,
	userName: '',
	firstName: '',
	lastName: '',
	lastOnline: null,
	totalDamageDealt: 0,
	changeMonsterIng: false,
	// 離線聯盟攻擊傷害 邏輯是 claim之後 會被設為null 所以動畫和運算應該是在null後運行 一登入的dialog則是有值出現
	offlineAllyDamage: null,
	noMoneyClicks: [],
	telegramId: '',
	referralCode: '',
	referralOpen: false,
	isKOL: false,
	levelsRange: [],
	rankName: {},
	rankIcon: {},
	soldierAvatar: {},
	referral: {},
	needToInit: false,
	tutorialFinished: getStorage('tutorialFinished'),
	recruitBarNotShow: getStorage('recruitBarNotShow'),
	walletAddress: '',
	redDot: {
		earn: false,
		recruit: false,
	},
	frensInvited: 0,
	referralRewardsSummed: 0,
};

export const loginAction = createAsyncThunk(
	'game/getLoginInfo',
	async ({ telegramId, username, firstName, lastName, languageCode, inviteCode, initData }) => {
		const inviteCodeForSend = (() => {
			if (inviteCode === 'earn') {
				return '';
			}

			if (inviteCode === 'fight') {
				return '';
			}

			return inviteCode;
		})();
		try {
			await sendAllyRequestsToServer();
			await sendOfflinePostRequestsToServer();
			const data = await fetchLoginInfo({
				telegramId,
				username,
				firstName,
				lastName,
				languageCode,
				referralCode: inviteCodeForSend,
				initData,
			});

			const { referralCode } = await fetchReferralCode({
				telegramId,
				token: data.token,
			});
			const levelData = await fetchLevelMapping({ token: data.token });
			return { username, firstName, lastName, referralCode, ...data, ...levelData };
		} catch (error) {
			Sentry.captureException(error);
			throw new Error(error);
		}
	},
);

export const levelMappingAction = createAsyncThunk('game/getLevelMapping', async () => {
	try {
		const data = await fetchLevelMapping();
		return data;
	} catch (error) {
		Sentry.captureException(error);
		throw new Error(error);
	}
});

export const postSkillUpdateAction = createAsyncThunk(
	'game/skillUpdate',
	async ({ skillId, coins }) => {
		try {
			const data = await postSkillUpdate({
				skillId,
				coins,
			});

			return data;
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	},
);

export const postMonsterKillAction = createAsyncThunk('game/killMonster', async ({ monsterId }) => {
	try {
		const data = await postMonsterKill({
			monsterId,
			timestamp: new Date(),
		});

		return data;
	} catch (error) {
		Sentry.captureException(error);
		throw error;
	}
});

export const gameSlice = createSlice({
	name: 'game',
	initialState,
	reducers: {
		attackMonsterAction: (state, action) => {
			const { isLastMonster, isByAttack = true } = action.payload;
			if (state.energy - state.ATK < 0 || state.currentMonster.remainedHealth <= 0) {
				return;
			}

			let monsterRemainedHealth;
			let coins;
			let damageDealt;
			let currentEnergy;
			// 最後一隻怪獸打不死
			if (isByAttack) {
				if (isLastMonster) {
					monsterRemainedHealth = state.monsterRemainedHealth;
				} else {
					monsterRemainedHealth =
						state.monsterRemainedHealth - state.ATK < 0
							? 0
							: state.monsterRemainedHealth - state.ATK;
				}

				coins = state.coins + Math.min(state.monsterRemainedHealth, state.ATK);
				damageDealt = state.totalDamageDealt + Math.min(state.monsterRemainedHealth, state.ATK);
				currentEnergy = state.energy - state.ATK < 0 ? 0 : state.energy - state.ATK;
			} else {
				monsterRemainedHealth = state.monsterRemainedHealth;
				coins = state.coins;
				damageDealt = state.totalDamageDealt;
				currentEnergy = state.energy;
			}

			state.coins = coins;
			state.totalDamageDealt = damageDealt;
			state.energy = currentEnergy;
			state.monsterRemainedHealth = monsterRemainedHealth;

			postAttack({
				monsterRemainedHealth,
				monsterId: state.currentMonster._id,
				damageDealt,
				coins,
				currentEnergy,
				timestamp: Date.now(),
			});
		},
		handleAnimationEndAction: (state, action) => {
			state.clicks = state.clicks.filter((click) => click.id !== action.payload);
		},
		handleAddEnergyAction: (state, action) => {
			state.energy = Math.min(state.maxEnergy, state.energy + state.energyToAddAnySecond);
		},
		handleAllyAction: (state, action) => {
			const lastMonster = state.monsterList[state.monsterList.length - 1];
			const isLastMonster = state.currentMonster._id === lastMonster._id;

			const allyValue = state.skillList.find((skill) => skill.name === 'ALLY')?.value;
			const coins = state.coins + Math.min(state.monsterRemainedHealth, allyValue);
			const damageDealt = state.totalDamageDealt + Math.min(state.monsterRemainedHealth, allyValue);
			const monsterRemainedHealth = isLastMonster
				? state.monsterRemainedHealth
				: state.monsterRemainedHealth - allyValue;

			state.totalDamageDealt = damageDealt;
			state.coins = coins;

			state.monsterRemainedHealth = monsterRemainedHealth;

			saveAllyRequest({
				coins,
				damageDealt,
				monsterRemainedHealth,
				monsterId: state.currentMonster._id,
				currentEnergy: state.energy,
			});
		},
		handleAddOfflineAllyAction: (state, action) => {
			const lastMonster = state.monsterList[state.monsterList.length - 1];
			const isLastMonster = state.currentMonster._id === lastMonster._id;

			let monsterRemainedHealth;
			let totalDamageDealt;
			let coins;
			if (isLastMonster) {
				monsterRemainedHealth = state.monsterRemainedHealth;
				totalDamageDealt = state.totalDamageDealt + state.offlineAllyDamage;
				coins = state.coins + state.offlineAllyDamage;
			} else {
				monsterRemainedHealth =
					state.monsterRemainedHealth - state.offlineAllyDamage < 0
						? 0
						: state.monsterRemainedHealth - state.offlineAllyDamage;
				totalDamageDealt =
					state.totalDamageDealt + Math.min(state.monsterRemainedHealth, state.offlineAllyDamage);
				coins = state.coins + Math.min(state.monsterRemainedHealth, state.offlineAllyDamage);
			}

			state.coins = coins;
			state.totalDamageDealt = totalDamageDealt;
			state.monsterRemainedHealth = monsterRemainedHealth;

			state.offlineAllyDamage = null;

			postAttack({
				monsterRemainedHealth: state.monsterRemainedHealth,
				monsterId: state.currentMonster._id,
				damageDealt: state.totalDamageDealt,
				coins: state.coins,
				currentEnergy: state.energy,
				timestamp: Date.now(),
			});
		},

		handleNoMoneyClicksAction: (state) => {
			state.noMoneyClicks = [...state.noMoneyClicks, { id: Date.now() }];
		},
		handleClearNoMoneyClicksAction: (state, action) => {
			state.noMoneyClicks = state.noMoneyClicks.filter((click) => click.id !== action.payload);
		},

		clearAllNoMoneyClicksAction: (state, action) => {
			state.noMoneyClicks = [];
		},
		setReferralOpenAction: (state, action) => {
			state.referralOpen = action.payload;
		},
		setAddCoinAction: (state, action) => {
			state.coins += action.payload;
		},
		setMinusCoinAction: (state, action) => {
			state.coins -= action.payload;
		},
		setEnergyFillAction: (state) => {
			state.energy = state.maxEnergy;
		},
		setReferral: (state, action) => {
			state.referral = null;
		},
		setNeedToInitToFalseAction: (state, action) => {
			state.needToInit = false;
		},
		setTutorialFinishedAction: (state, action) => {
			state.tutorialFinished = true;
		},
		setRecruitBarNotShowAction: (state, action) => {
			state.recruitBarNotShow = true;
		},
		setRedDotAction: (state, action) => {
			state.redDot[action.payload] = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(loginAction.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(loginAction.fulfilled, (state, action) => {
			if (!get(action, 'payload')) {
				return;
			}

			setStorage('token', get(action, 'payload.token'));
			state.coins = get(action, 'payload.user.coins');
			state.ATK = get(action, 'payload.user.atk');

			state.maxEnergy = get(action, 'payload.user.energy.max');

			state.energyToAddAnySecond = get(action, 'payload.user.energy.recoveryPerSecond', 0);

			const timeDifference = moment().diff(get(action, 'payload.user.lastOnline'), 'seconds');
			// 累計秒數 * 每秒回復量
			const energyBackWhenClose =
				timeDifference * get(action, 'payload.user.energy.recoveryPerSecond', 0);
			state.energy = Math.min(
				get(action, 'payload.user.energy.max'),
				get(action, 'payload.user.energy.current') + energyBackWhenClose,
			);
			state.monsterList = get(action, 'payload.user.monsterList');

			state.totalDamageDealt = get(action, 'payload.user.totalDamageDealt', 0);
			state.userName = get(action, 'payload.username');
			state.firstName = get(action, 'payload.firstName');
			state.lastName = get(action, 'payload.lastName');
			state.levelsRange = get(action, 'payload.level');
			state.telegramId = get(action, 'payload.user.telegramId');
			state.rankName = get(action, 'payload.rankName');
			state.rankIcon = get(action, 'payload.rankIcon');
			state.soldierAvatar = get(action, 'payload.avatar');
			state.referral = get(action, 'payload.referral');
			state.walletAddress = get(action, 'payload.user.walletAddress');
			state.redDot = get(action, 'payload.user.redDot');
			state.frensInvited = get(action, 'payload.user.frensInvited');
			state.referralRewardsSummed = get(action, 'payload.user.referralRewardsSummed');

			const currentMonster = get(action, 'payload.user.monsterList').find(
				(monster) => !monster.defeated,
			);
			const nextMonster = get(action, 'payload.user.monsterList').find((monster) => monster.locked);

			state.currentMonster = currentMonster;
			state.nextMonster = nextMonster;
			state.monsterTotalBlood = get(currentMonster, 'baseHealth');
			state.monsterRemainedHealth = get(currentMonster, 'remainedHealth');

			state.skillList = get(action, 'payload.user.skillList');
			state.totalDamage = get(action, 'payload.user.totalDamage', 0);
			state.offlineAllyDamage = get(action, 'payload.user.offlineAllyDamage', null);
			state.referralCode = get(action, 'payload.referralCode', '');
			state.isKOL = get(action, 'payload.user.isKOL', false);
		});
		builder.addCase(loginAction.rejected, (state, action) => {
			state.isLoading = false;
		});

		builder.addCase(postMonsterKillAction.pending, (state) => {
			state.changeMonsterIng = true;
			state.needToInit = true;
		});
		builder.addCase(postMonsterKillAction.fulfilled, (state, action) => {
			if (!get(action, 'payload')) {
				return;
			}
			state.monsterList = get(action, 'payload');
			const currentMonster = get(action, 'payload').find((monster) => !monster.defeated);
			const nextMonster = get(action, 'payload').find((monster) => monster.locked);

			state.currentMonster = currentMonster;
			state.nextMonster = nextMonster;
			state.monsterTotalBlood = get(currentMonster, 'baseHealth');
			state.monsterRemainedHealth = get(currentMonster, 'remainedHealth');
			state.offlineAllyDamage = null;
			state.changeMonsterIng = false;
		});
		builder.addCase(postMonsterKillAction.rejected, (state, action) => {
			state.changeMonsterIng = false;
		});

		builder.addCase(postSkillUpdateAction.fulfilled, (state, action) => {
			if (!get(action, 'payload')) {
				return;
			}
			state.skillList = get(action, 'payload.skillList');
			// state.coins = get(action, 'payload.coins');
			state.ATK = get(action, 'payload.atk');
			state.maxEnergy = get(action, 'payload.energy.max');
			state.energyToAddAnySecond = get(action, 'payload.energy.recoveryPerSecond', 0);
		});
	},
});

export const {
	attackMonsterAction,
	handleAnimationEndAction,
	handleAddEnergyAction,
	handleAllyAction,
	handleAddOfflineAllyAction,
	handleNoMoneyClicksAction,
	handleClearNoMoneyClicksAction,
	clearAllNoMoneyClicksAction,
	setReferralOpenAction,
	setAddCoinAction,
	setReferral,
	setEnergyFillAction,
	setMinusCoinAction,
	setNeedToInitToFalseAction,
	setTutorialFinishedAction,
	setRecruitBarNotShowAction,
	setRedDotAction,
} = gameSlice.actions;

export const gameReducer = gameSlice.reducer;
