import styled from '@emotion/styled/macro';
import { Button } from '@metacrm/metacrm-material-ui/dist/Button';
import Box from '@mui/material/Box';
import { yellow } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import Slider from 'react-slick';

import isStyledPropsValid from 'SRC/utils/isStyledPropsValid';

const images = {
	blue: require('SRC/assets/images/popup.png'),
	black: require('SRC/assets/images/popup_black.png'),
};

export const StyledVideoPageContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	minHeight: '100dvh',
	background: '#333',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
}));
