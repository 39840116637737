import numeral from 'numeral';

// export function formatNumber(num) {
// 	if (num < 1000) return num;
// 	return numeral(num).format('0.[0]a').toUpperCase();
// }

export function formatNumber(num) {
	// 檢查 num 是否為數字且不為 null 或 undefined
	if (num === null || num === undefined) {
		return ''; // 或者返回一個預設值，如 '0'
	}

	if (num < 1000) return num.toString();

	const formatted = numeral(num).format('0.[00]a').toUpperCase();
	const [numberPart] = formatted.match(/([\d.]+)/);

	// 判斷小數點前的數字長度
	const integerPartLength = numberPart.split('.')[0].length;

	let formatString;
	if (integerPartLength === 1) {
		formatString = '0.00a'; // 一位數，顯示兩位小數
	} else if (integerPartLength === 2) {
		formatString = '0.0a'; // 兩位數，顯示一位小數
	} else {
		formatString = '0a'; // 三位數，不顯示小數
	}

	// 格式化數字
	let result = numeral(num).format(formatString).toUpperCase();

	// 移除多餘的 ".00" 或 ".0"，適用於 K、M、B、T 單位
	result = result.replace(/\.00([KMBT])$/, '$1'); // 將 ".00" 移除
	result = result.replace(/\.0([KMBT])$/, '$1'); // 將 ".0" 移除

	return result;
}

export function detectDevice() {
	const userAgent = navigator.userAgent.toLowerCase();
	console.log('userAgent: ', userAgent);

	const isMobile = /iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent);

	if (isMobile) {
		return 'mobile';
	}
	return 'desktop';
}
