/* eslint-disable consistent-return */
/* eslint-disable no-return-await */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import axios from 'axios';
import Dexie from 'dexie';

import { getStorage } from './storage';

import { generateCustomNonce, generateHMAC } from 'SRC/api/base/crypto';
// import { apiUrl } from 'SRC/features/configure';
import apiConfig from 'SRC/config';

const { apiUrl } = apiConfig;

const db = new Dexie('TrayTrackingPostDB');

db.version(1).stores({
	postrequest: '++id, url, authHeader, payload',
	allyRequest: 'key', // 設定 'key' 為主鍵
});

async function fetchApi(record) {
	const nonce = generateCustomNonce(16);
	const timestamp = Date.now();
	console.log('fetchApi: record.payload: ', record.payload);

	const signature = generateHMAC({ data: JSON.parse(record.payload), nonce, timestamp });
	return await axios.post(record.url, JSON.parse(record.payload), {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getStorage('token') || ''}`,
			'X-Signature': signature,
			'X-Nonce': nonce,
			'X-timestamp': timestamp,
		},
	});
}

// offline attack post requests

// Get all records from the database
export async function getAllRecords(setIndexDB) {
	try {
		const records = await db.postrequest.toArray();
		console.log('All records in the database:', records);
		setIndexDB(records);
		return records;
	} catch (error) {
		Sentry.captureException(error);
		console.error('Failed to retrieve records from IndexedDB', error);
	}
}

// Save attack post requests to the database
export async function saveRequest(url, authHeader, payload) {
	try {
		await db.postrequest.add({ url, authHeader, payload });
		console.log('Request saved to IndexedDB');
	} catch (error) {
		Sentry.captureException(error);
		console.error('Failed to save request to IndexedDB:', error);
	}
}

// Clear all attack post requests from IndexedDB
export async function clearIndexedDB() {
	try {
		await Promise.all([db.postrequest.clear(), db.allyRequest.clear()]);
		console.log('IndexedDB cleared');
	} catch (error) {
		Sentry.captureException(error);
		console.error('Failed to clear IndexedDB:', error);
	}
}

// Send attack post requests to server and delete from IndexedDB
async function sendAttackRequestsToServer(records, results) {
	for (const record of records) {
		try {
			console.log('Sending record', record);

			const response = await fetchApi(record);

			if (response.status === 200 || response.status === 201) {
				await db.postrequest.delete(record.id);
				results.push(
					`Successfully sent request to server and deleted from IndexedDB:${record.id}\n coin:${
						JSON.parse(record.payload)?.coins
					}` || 0,
				);
			} else {
				console.log('Server responded with an error:', response.status);
				results.push(`Server responded with an error:${response.status}`); // Stop processing further requests
			}
		} catch (error) {
			Sentry.captureException(error);
			console.log('Exception while sending post request to server:', error);
			results.push(`Exception while sending post request to server:${error}`); // Stop processing further requests
		}
	}
}

// Send all attack post requests to server and delete from IndexedDB
export default async function sendOfflinePostRequestsToServer() {
	try {
		const records = await db.postrequest.toArray();
		const results = [];
		if (records.length > 0) {
			await sendAttackRequestsToServer(records, results);
		}

		return results;
	} catch (error) {
		Sentry.captureException(error);
		console.error('Failed to retrieve records from IndexedDB', error);
	}
}

// interval ally request into indexDB

// add ally request to database
export async function saveAllyRequest(payload) {
	try {
		const timestamp = new Date().getTime();
		const key = 'allyRequest'; // 定義唯一鍵，用於識別記錄

		// 使用 put 方法替代 add 方法
		// 這邊JSON.stringify 是為了fetchApi這個function裡面的parse 為了跟service回傳的格式一樣
		await db.allyRequest.put({
			key,
			url: `${apiUrl}api/game/attack`,
			payload: JSON.stringify({ ...payload, timestamp }),
		});
	} catch (error) {
		Sentry.captureException(error);
		console.error('Failed to save API endpoint to IndexedDB:', error);
	}
}

// send ally request to server
export async function sendAllyRequestsToServer() {
	try {
		const key = 'allyRequest'; // 使用與保存時相同的鍵值
		const record = await db.allyRequest.get(key);
		if (!record) return;
		const { data } = await fetchApi(record);
		await db.allyRequest.delete(key);
		console.log('Request processed and cleaned from IndexedDB');
		return data;
	} catch (err) {
		Sentry.captureException(err);
		console.log('Error while sending ally request to server:', err);
	}
}
