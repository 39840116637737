import React, { useState, useEffect, useRef, useMemo } from 'react';

import Grid from '@metacrm/metacrm-material-ui/dist/Grid';
import Box from '@mui/material/Box';
import * as Sentry from '@sentry/react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import {
	StyledEarnContainer,
	StyledEarnTop,
	StyledDailyCheckInContainer,
	StyledDailyCheckInSingleDay,
	StyledColorBtn,
	StyledCategoryTitle,
	StyledColorBtnContainer,
	StyledEarnContainerBackground,
} from './Earn.styles';

import {
	fetchDailyCheckIn,
	postDailyCheckIn,
	fetchBooster,
	postBooster,
	fetchLevelReward,
	postLevelReward,
	fetchMissions,
	postEarnMission,
} from 'SRC/api/earn';
import StyledDialogBtn from 'SRC/components/Button/StyledDialogBtn';
import Daily from 'SRC/components/Earn/Daily';
import { useErrorPopup } from 'SRC/components/ErrorDialog/ErrorDialogProvider';
import LoadingComponent from 'SRC/components/LoadingComponent/LoadingComponent';
import configs from 'SRC/config';
import { StyledDialog } from 'SRC/Dialog.styles';
import {
	setReferralOpenAction,
	setAddCoinAction,
	setEnergyFillAction,
	setRedDotAction,
} from 'SRC/store/game/game.reducer';
import {
	selectFirstName,
	selectGameTotalDamageDealt,
	selectGameCoins,
	selectTelegramId,
	selectReferralCode,
} from 'SRC/store/game/game.selector';
import { formatNumber } from 'SRC/utils/common';
import './fade.css';

function Earn(props) {
	const coins = useSelector(selectGameCoins);
	const navigate = useNavigate();
	const firstName = useSelector(selectFirstName);
	const totalDamageDealt = useSelector(selectGameTotalDamageDealt);
	const telegramId = useSelector(selectTelegramId);
	const dispatch = useDispatch();
	const [targetDaily, setTargetDaily] = useState(null);
	const [onBoardingInfo, setOnBoardingInfo] = useState(null);

	const [isLoading, setIsLoading] = useState(false);
	const [levelRewardLoading, setLevelRewardLoading] = useState(false);
	const [boosterLoading, setBoosterLoading] = useState(false);
	const errorHandler = useErrorPopup();
	const queryClient = useQueryClient();
	const [levelReward, setLevelReward] = useState(null);
	const [energyBoosterDialogShow, setEnergyBoosterDialogShow] = useState(false);
	const [imagesLoaded, setImagesLoaded] = useState(0);
	const nodeRef = useRef(null);
	const referralCode = useSelector(selectReferralCode);

	const handleFetchDailyCheckIn = async () => {
		try {
			const data = await fetchDailyCheckIn();
			const lastTenItems = data?.days?.slice(-10);
			return lastTenItems;
			// setDailyList(lastTenItems);
		} catch (error) {
			console.error('error: ', error);
			Sentry.captureException(error);
			return null;
		}
	};

	const handleFetchBooster = async () => {
		try {
			const data = await fetchBooster();
			return data?.boosters;
			// setBoosterNumber(data?.boosters);
		} catch (error) {
			Sentry.captureException(error);
			console.error('error: ', error);
			return null;
		}
	};

	const handleFetchFetchMissions = async () => {
		try {
			const data = await fetchMissions();
			return data?.missions;
		} catch (error) {
			Sentry.captureException(error);
			console.error('error: ', error);
			return null;
		}
	};

	const handleFetchLevelReward = async ({ damageDealt }) => {
		try {
			const data = await fetchLevelReward({ damageDealt });
			// setLevelRewardInfo(data);
			return data;
		} catch (error) {
			Sentry.captureException(error);
			console.error('error: ', error);
			return null;
		}
	};

	const handleImageLoad = () => {
		setImagesLoaded((prevCount) => prevCount + 1);
	};

	const { data: dailyList, isSuccess: dailyListIsSuccess } = useQuery({
		queryKey: ['fetchDailyCheckIn'],
		queryFn: () => handleFetchDailyCheckIn(),
	});

	const { data: boosterNumber, isSuccess: boosterNumberIsSuccess } = useQuery({
		queryKey: ['fetchBooster'],
		queryFn: () => handleFetchBooster(),
	});

	const { data: missionList, isSuccess: missionListIsSuccess } = useQuery({
		queryKey: ['fetchMissions'],
		queryFn: () => handleFetchFetchMissions(),
	});

	const { data: levelRewardInfo, isSuccess: levelRewardInfoIsSuccess } = useQuery({
		queryKey: ['fetchLevelReward'],
		queryFn: () => handleFetchLevelReward({ damageDealt: totalDamageDealt }),
	});

	const setDailyList = (data) => {
		queryClient.setQueryData(['fetchDailyCheckIn'], data);
	};

	const handlePostBoosterDialog = async () => {
		setEnergyBoosterDialogShow(true);
	};

	const handlePostBooster = async () => {
		try {
			setBoosterLoading(true);
			const data = await postBooster();
			queryClient.setQueryData(['fetchBooster'], data?.boosters);
			dispatch(setEnergyFillAction({}));
		} catch (error) {
			errorHandler({ errorText: 'Network Error...' }).catch(() => {});
			Sentry.captureException(error);
		} finally {
			setBoosterLoading(false);
			setEnergyBoosterDialogShow(false);
		}
	};

	const handlePostLevelReward = async () => {
		try {
			setLevelRewardLoading(true);
			const data = await postLevelReward({ damageDealt: totalDamageDealt, coins });
			dispatch(setAddCoinAction(parseInt(levelRewardInfo.claimable, 10)));
			queryClient.setQueryData(['fetchLevelReward'], data);
		} catch (error) {
			errorHandler({ errorText: 'Network Error...' }).catch(() => {});
			Sentry.captureException(error);
		} finally {
			setLevelRewardLoading(false);
			setLevelReward(null);
		}
	};

	const handlePostLevelRewardDialog = async () => {
		setLevelReward(levelRewardInfo);
	};

	const handleJoinOnboarding = async () => {
		try {
			const data = await postEarnMission({ _id: onBoardingInfo?._id, status: 'join', coins });
		} catch (error) {
			errorHandler({ errorText: 'Network Error...' }).catch(() => {});
			Sentry.captureException(error);
		}
	};

	const handleCheckOnboarding = async () => {
		try {
			setIsLoading(true);
			const data = await postEarnMission({ _id: onBoardingInfo?._id, status: 'check', coins });
			dispatch(setAddCoinAction(parseInt(onBoardingInfo.coin, 10)));
			queryClient.setQueryData(['fetchMissions'], data?.missions);
		} catch (error) {
			if (error.message === 'Network Error') {
				errorHandler({ errorText: 'Network Error...' }).catch(() => {});
				return;
			}

			errorHandler({ errorText: 'Check Failed' }).catch(() => {});
			Sentry.captureException(error);
			return;
		} finally {
			setIsLoading(false);
			setOnBoardingInfo(null);
		}
	};

	const dailyBoosters = [
		{
			img: require(`SRC/assets/images/energy_bottle.png`),
			title: 'Full Recovery',
			content: (
				<Box className='strokeText' data-storke={`${boosterNumber}/3 Available`}>
					{boosterNumber}/3 Available
				</Box>
			),
			disabled: !boosterNumber,
			btnColor: 'blue',
			callback: () => handlePostBoosterDialog(),
		},
	];

	const bonus = [
		{
			img: require(`SRC/assets/images/box_brown.png`),
			title: `LV ${levelRewardInfo?.level} Reward`,
			content: (
				<Box display='flex' alignItems='center'>
					<Box className='strokeText' data-storke='Claimable:'>
						Claimable:
					</Box>
					<Box
						className='strokeText yellow'
						data-storke={`+ ${formatNumber(levelRewardInfo?.claimable)}`}
						ml='5px'
					>
						+ {formatNumber(levelRewardInfo?.claimable)}
					</Box>
				</Box>
			),
			disabled: levelRewardInfo?.claimable <= 0,
			btnColor: 'yellow',
			callback: () => handlePostLevelRewardDialog(),
		},
		{
			img: require(`SRC/assets/images/box_pink.png`),
			title: 'Send Gift to friend up to',
			content: (
				<Box display='flex' alignItems='center'>
					<Box
						className='strokeText'
						data-storke='+50M'
						sx={{ color: '#FFC203', marginRight: '5px' }}
					>
						+50M
					</Box>{' '}
					<Box component='img' width={20} height={20} src={require(`SRC/assets/images/coin.png`)} />
				</Box>
			),
			disabled: false,
			btnColor: 'yellow',
			callback: () => navigate('/recruit'),
		},
	];

	const handleOnboarding = ({ onBoardingItem }) => {
		setOnBoardingInfo(onBoardingItem);
	};

	useEffect(() => {
		dispatch(setRedDotAction('earn'));
	}, []);

	const onBoarding = missionList
		?.filter((mission) => mission.category === 'connect')
		?.map((mission, index) => ({
			img: mission.icon,
			title: mission.title,
			content: (
				<Box display='flex' alignItems='center'>
					<Box
						className='strokeText'
						data-storke={formatNumber(mission.reward)}
						sx={{ color: '#FFC203', marginRight: '5px' }}
					>
						{formatNumber(mission.reward)}
					</Box>{' '}
					<Box component='img' width={20} height={20} src={require(`SRC/assets/images/coin.png`)} />
				</Box>
			),
			disabled: mission.status === 'complete',
			btnColor: 'green',
			coin: mission.reward,
			text: mission.title,
			callback: (item) => handleOnboarding({ onBoardingItem: item }),
			joinUrl: mission.url,
			_id: mission._id,
			onLoad: handleImageLoad,
		}));

	const botUrl = useMemo(() => `${configs.botUrl}${referralCode}`, [referralCode]);

	const explore = missionList
		?.filter((mission) => mission.category === 'ad')
		?.map((mission, index) => ({
			img: mission.icon,
			title: mission.title,
			content: (
				<Box display='flex' alignItems='center'>
					<Box
						className='strokeText'
						data-storke={formatNumber(mission.reward)}
						sx={{ color: '#FFC203', marginRight: '5px' }}
					>
						{formatNumber(mission.reward)}
					</Box>{' '}
					<Box component='img' width={20} height={20} src={require(`SRC/assets/images/coin.png`)} />
				</Box>
			),
			disabled: mission.status === 'complete',
			btnColor: 'blue',
			coin: mission.reward,
			text: mission.title,
			callback: (item) => handleOnboarding({ onBoardingItem: item }),
			joinUrl:
				mission.title === 'Share Legion to X'
					? `${mission.url}&url=${encodeURIComponent(botUrl)}`
					: mission.url,
			_id: mission._id,
		}));

	const renderColorBtn = ({ btnItem, index }) => (
		<StyledColorBtnContainer key={index}>
			<StyledColorBtn
				btnColor={btnItem.btnColor}
				disabled={btnItem.disabled}
				onClick={btnItem.callback && !btnItem.disabled ? () => btnItem.callback(btnItem) : null}
			>
				<Box display='flex' alignItems='center'>
					<Box
						component='img'
						sx={{ objectFit: 'contain' }}
						width={40}
						height={40}
						src={btnItem.img}
						mr='20px'
						onLoad={btnItem.onLoad}
					/>
					<Box>
						<Box className='strokeText' data-storke={btnItem.title} mb='5px'>
							{btnItem.title}
						</Box>
						<Box>{btnItem.content}</Box>
					</Box>
				</Box>
			</StyledColorBtn>
			<Box sx={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)' }}>
				{!btnItem.disabled ? (
					<Box component='img' src={require(`SRC/assets/images/arrow.svg`).default} />
				) : (
					<Box
						component='img'
						width={21}
						height={17}
						src={require(`SRC/assets/images/check.png`)}
					/>
				)}
			</Box>
		</StyledColorBtnContainer>
	);

	const renderJoinBtn = () => {
		if (onBoardingInfo?.title.toLowerCase().includes('watch')) {
			return 'Watch';
		}
		if (onBoardingInfo?.title === 'Share Legion to X') {
			return 'Share';
		}
		return 'Join';
	};

	return (
		<>
			{telegramId && (
				<StyledEarnContainerBackground>
					<Box
						sx={{
							transform: imagesLoaded < 10 ? 'scaleY(1)' : 'scaleY(0)',
							transformOrigin: 'top',
							overflow: 'hidden',
							height: imagesLoaded < 10 ? '85vh' : 0,
						}}
					>
						<LoadingComponent />
					</Box>

					<StyledEarnContainer
						ref={nodeRef}
						sx={{
							transform: imagesLoaded < 10 ? 'scaleY(0)' : 'scaleY(1)',
							transformOrigin: 'top',
							overflow: 'hidden',
							height: imagesLoaded < 10 ? '0' : 'auto',
						}}
					>
						<>
							{!isEmpty(dailyList) && (
								<>
									<StyledEarnTop className='strokeText' data-storke='Daily Check-In'>
										Daily Check-In
									</StyledEarnTop>

									<StyledDailyCheckInContainer component={Grid} container>
										{dailyList?.map((daily, index) => (
											<Grid
												key={index}
												item
												xs={12 / 5}
												sx={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													marginBottom: '10px',
												}}
											>
												<Daily
													daily={daily}
													setDailyList={setDailyList}
													setTargetDaily={setTargetDaily}
													onLoad={handleImageLoad}
												/>
											</Grid>
										))}
									</StyledDailyCheckInContainer>
								</>
							)}

							{boosterNumber !== undefined && (
								<>
									<Box>
										<StyledCategoryTitle>Daily Boosters</StyledCategoryTitle>
										{dailyBoosters.map((item, index) => renderColorBtn({ btnItem: item, index }))}
									</Box>
								</>
							)}

							{levelRewardInfo !== undefined && (
								<Box>
									<StyledCategoryTitle>Bonus</StyledCategoryTitle>
									{bonus.map((item, index) => renderColorBtn({ btnItem: item, index }))}
								</Box>
							)}

							{!isEmpty(missionList) && (
								<>
									<Box>
										<StyledCategoryTitle>Onboarding</StyledCategoryTitle>
										{onBoarding?.map((item, index) => renderColorBtn({ btnItem: item, index }))}
									</Box>

									<Box>
										<StyledCategoryTitle>Explore More</StyledCategoryTitle>
										{explore?.map((item, index) => renderColorBtn({ btnItem: item, index }))}
									</Box>
								</>
							)}

							<StyledDialog open={Boolean(targetDaily)} show={Boolean(targetDaily)} bgColor='black'>
								<Box display='flex' flexDirection='column' alignItems='center'>
									<Box display='flex' alignItems='center' mb='54px'>
										<Box
											fontSize='36px'
											className='strokeText'
											data-storke={`${targetDaily?.title} Reward`}
										>
											{targetDaily?.title} Reward
										</Box>
									</Box>

									<Box position='relative'>
										<Box
											sx={{
												position: 'absolute',
												top: '55px',
												left: '50%',
												transform: 'translateX(-50%)',
												fontSize: '30px',
											}}
										>
											<Box
												className='strokeText thick'
												data-storke={formatNumber(targetDaily?.reward)}
											>
												{formatNumber(targetDaily?.reward)}
											</Box>
										</Box>

										<Box
											component='img'
											mb='5px'
											width='102px'
											src={require(`SRC/assets/images/daily-open-book.png`)}
										/>
									</Box>

									<Box mt='54px'>
										<StyledDialogBtn
											className='strokeText thick'
											data-storke='Done'
											onClick={() => {
												setTargetDaily(null);
											}}
											isLoading={isLoading}
										>
											Done
										</StyledDialogBtn>
									</Box>
								</Box>
							</StyledDialog>

							{/* onboarding Dialog 				 */}
							<StyledDialog
								open={Boolean(onBoardingInfo)}
								show={Boolean(onBoardingInfo)}
								bgColor='black'
							>
								<Box
									component='img'
									src={require('SRC/assets/images/dialog-cancel.png')}
									onClick={() => {
										setOnBoardingInfo(null);
									}}
									sx={{
										position: 'absolute',
										top: '20px',
										right: '20px',
									}}
								/>

								<Box display='flex' flexDirection='column' alignItems='center'>
									<Box
										component='img'
										mb='5px'
										height='100px'
										width='100px'
										src={onBoardingInfo?.img}
									/>

									<Box
										sx={{ fontSize: '24px' }}
										className='strokeText thick'
										data-storke={onBoardingInfo?.text}
									>
										{onBoardingInfo?.text}
									</Box>

									<Box display='flex' alignItems='center'>
										<Box
											sx={{ fontSize: '36px' }}
											className='strokeText thick'
											data-storke={`+ ${formatNumber(onBoardingInfo?.coin)}`}
										>
											+ {formatNumber(onBoardingInfo?.coin)}
										</Box>
										<Box
											component='img'
											width={40}
											height={40}
											ml='10px'
											src={require(`SRC/assets/images/coin.png`)}
										/>
									</Box>

									<Box mt='16px' display='flex' alignItems='center' justifyContent='center'>
										<StyledDialogBtn
											onClick={() => {
												handleJoinOnboarding();
												window.open(onBoardingInfo.joinUrl);
											}}
											sx={{
												mr: '10px',
											}}
										>
											{renderJoinBtn()}
										</StyledDialogBtn>

										<StyledDialogBtn
											onClick={() => {
												handleCheckOnboarding();
											}}
											isLoading={isLoading}
										>
											Check
										</StyledDialogBtn>
									</Box>
								</Box>
							</StyledDialog>

							{/* Level Reward Dialog */}
							<StyledDialog open={Boolean(levelReward)} show={Boolean(levelReward)} bgColor='black'>
								<Box
									component='img'
									src={require('SRC/assets/images/dialog-cancel.png')}
									onClick={() => {
										setLevelReward(null);
									}}
									sx={{
										position: 'absolute',
										top: '20px',
										right: '20px',
									}}
								/>

								<Box display='flex' alignItems='center' justifyContent='center' mt='20px'>
									<Box
										component='img'
										width={31}
										height={40}
										src={require('SRC/assets/images/up_arrow.png')}
										mr='15px'
									/>
									<Box
										sx={{ fontSize: '36px', textAlign: 'center' }}
										className='strokeText'
										data-storke={`LV ${levelReward?.level}`}
									>
										LV {levelReward?.level}
									</Box>
								</Box>

								<Box
									sx={{ fontSize: '24px', textAlign: 'center', marginTop: '16px' }}
									className='strokeText'
									data-storke='Level Reward:'
								>
									Level Reward:
								</Box>

								<Box display='flex' alignItems='center' justifyContent='center'>
									<Box className='strokeText' sx={{ fontSize: '36px' }}>
										+{formatNumber(levelReward?.claimable)}
									</Box>

									<Box
										width={40}
										height={40}
										sx={{
											marginLeft: '8.5px',
										}}
										component='img'
										src={require(`SRC/assets/images/coin.png`)}
									/>
								</Box>

								<Box display='flex' flexDirection='column' alignItems='center'>
									<Box mt='16px' display='flex' alignItems='center' justifyContent='center'>
										<StyledDialogBtn
											onClick={() => {
												handlePostLevelReward();
											}}
											isLoading={levelRewardLoading}
										>
											Claim
										</StyledDialogBtn>
									</Box>
								</Box>
							</StyledDialog>

							{/* Energy Booster Dialog */}
							<StyledDialog
								open={energyBoosterDialogShow}
								show={energyBoosterDialogShow}
								bgColor='black'
							>
								<Box
									component='img'
									src={require('SRC/assets/images/dialog-cancel.png')}
									onClick={() => {
										setEnergyBoosterDialogShow(false);
									}}
									sx={{
										position: 'absolute',
										top: '20px',
										right: '20px',
									}}
								/>
								<Box display='flex' flexDirection='column' alignItems='center'>
									<Box display='flex' flexDirection='column' alignItems='center'>
										<Box
											className='strokeText'
											sx={{
												fontSize: '24px',
												marginTop: '16px',
												marginBottom: '16px',
												whiteSpace: 'nowrap',
											}}
											data-storke='Energy fully restored!'
										>
											Energy fully restored!
										</Box>
										<Box
											component='img'
											width='134px'
											height='134px'
											src={require('SRC/assets/images/recovery-icon.png')}
										/>
									</Box>
									<Box mt='50px' display='flex' alignItems='center' justifyContent='center'>
										<StyledDialogBtn
											onClick={() => {
												handlePostBooster();
											}}
											isLoading={boosterLoading}
										>
											Claim
										</StyledDialogBtn>
									</Box>
								</Box>
							</StyledDialog>
						</>
					</StyledEarnContainer>
				</StyledEarnContainerBackground>
			)}
		</>
	);
}

Earn.propTypes = {};

export default Earn;
