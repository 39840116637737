import React, { useState } from 'react';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { StyledProductButtonContainer, StyledUpgradeBtn } from './ProductButton.styles';

import { useErrorPopup } from 'SRC/components/ErrorDialog/ErrorDialogProvider';
import usePlaySound from 'SRC/hooks/usePlaySound';
import {
	postSkillUpdateAction,
	handleNoMoneyClicksAction,
	setMinusCoinAction,
	setAddCoinAction,
} from 'SRC/store/game/game.reducer';
import { selectGameCoins } from 'SRC/store/game/game.selector';
import { formatNumber } from 'SRC/utils/common';

function ProductButton({ name, level, value, price, ...props }) {
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const errorHandler = useErrorPopup();
	const coins = useSelector(selectGameCoins);
	const playSound = usePlaySound('skill');

	const handleClick = async () => {
		if (isLoading) return;
		if (coins < price) {
			dispatch(handleNoMoneyClicksAction());

			return;
		}
		setIsLoading(true);
		playSound();
		try {
			dispatch(setMinusCoinAction(price));
			// eslint-disable-next-line react/prop-types
			await dispatch(postSkillUpdateAction({ skillId: props._id, coins })).unwrap();
			// console.log('data: ', data);
		} catch (err) {
			if (err.message === 'Not enough coins') {
				errorHandler({ errorText: err.message }).catch(() => {});
			} else {
				errorHandler({ errorText: 'Network Error...' }).catch(() => {});
			}
			dispatch(setAddCoinAction(price));
		} finally {
			setIsLoading(false);
		}
	};

	const skillMapping = {
		ATK: {
			name: 'ATK',
			img: require('SRC/assets/images/Attack.png'),
		},
		'ENERGY BAR': {
			name: 'ENERGY',
			img: require('SRC/assets/images/thunder.png'),
		},
		RECOVERY: {
			name: 'RECOVERY',
			img: require('SRC/assets/images/recovery.png'),
		},
		ALLY: {
			name: 'ALLY',
			img: require('SRC/assets/images/ally.png'),
		},
		MISSILE: {
			name: 'MISSILE',
			img: require('SRC/assets/images/missile.png'),
		},
	};

	return (
		<Box m='0 2px' style={{ minWidth: 'calc(25vw - 15px)' }}>
			<StyledProductButtonContainer>
				<Box mb='10px'>
					{' '}
					<Box component='span' className='strokeText' data-storke='Lv' mr='4px'>
						Lv
					</Box>
					<Box component='span' className='strokeText' data-storke={level}>
						{level}
					</Box>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<Box height={40} width={40} component='img' src={skillMapping?.[name]?.img} alt={name} />
				</Box>
				<Box
					mr='auto'
					sx={{
						fontSize: '13px',
						fontWeight: 800,
						display: 'flex',
						flexDirection: 'column',
						lineHeight: '25px',
						width: '100%',
						textAlign: 'center',
						marginBottom: '14px',
					}}
				>
					<Box className='strokeText' data-storke={skillMapping?.[name].name} mr='4px'>
						{skillMapping?.[name].name}
					</Box>
					<Box
						className='strokeText'
						data-storke={`${formatNumber(value)}`}
						fontSize='14px'
						sx={{ whiteSpace: 'nowrap' }}
					>
						{`${formatNumber(value)}`}
					</Box>
				</Box>
				<StyledUpgradeBtn
					noMoney={coins < price || price === 0}
					isLoading={isLoading}
					onClick={isLoading ? null : handleClick}
				>
					{price === 0 ? (
						<>
							<Box className='strokeText' data-storke='Skill' mb='6px'>
								Skill
							</Box>
							<Box className='strokeText' data-storke='Max' mb='6px'>
								Max
							</Box>
						</>
					) : (
						<>
							{/* <Box className='strokeText' data-storke='Upgrade' mb='6px'>
								Upgrade
							</Box> */}
							<Box display='flex' alignItems='center'>
								<Box
									height={14}
									width={14}
									component='img'
									mr='5px'
									src={require('SRC/assets/images/coin.png')}
								/>
								<Box className='strokeText' data-storke={formatNumber(price)} fontSize='12px'>
									{formatNumber(price)}
								</Box>
							</Box>
						</>
					)}
				</StyledUpgradeBtn>
			</StyledProductButtonContainer>
		</Box>
	);
}

ProductButton.propTypes = {
	name: PropTypes.oneOf(['ATK', 'ENERGY BAR', 'RECOVERY', 'ALLY', 'MISSILE']),
	level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ProductButton;
