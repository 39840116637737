import store from 'store2';

const storage = store;

export const getStorage = (key) => storage.get(key);

export const setStorage = (key, value) => storage.set(key, value);

export const removeStorage = (key) => storage.remove(key);

export default {
	getStorage,
	setStorage,
	removeStorage,
};
