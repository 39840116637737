import { keyframes } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Button } from '@metacrm/metacrm-material-ui/dist/Button';
import Box from '@mui/material/Box';

import isStyledPropsValid from 'SRC/utils/isStyledPropsValid';

export const StyledEarnContainerBackground = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	background: 'linear-gradient(180deg, #0F1221 0%, #151A2F 82.5%, #43957C 100%)',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'top center',
	minHeight: '100%',
	paddingBottom: '60px',
}));

export const StyledEarnContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, sx }) => ({
	position: 'relative',
	flexDirection: 'column',
	color: theme.customColors.white,
	// minHeight: '100vh',
	transition: 'all 0.2s ease',
	...sx,
}));

export const StyledEarnTop = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	background: '#FFB700',
	textAlign: 'center',
	fontSize: '22px',
	boxShadow: `inset 0px 4px #ffdb02, inset 0px -4px #ff9301`,
	padding: '14px 0',
}));

export const StyledDailyCheckInContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	marginTop: '20px',
}));

export const StyledDailyCheckInSingleDay = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, isLoading }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	...(isLoading && {
		opacity: '0.5',
	}),
}));

const images = {
	blue: require('SRC/assets/images/earn_blue_btn.png'),
	green: require('SRC/assets/images/earn_green_btn.png'),
	yellow: require('SRC/assets/images/earn_yellow_btn.png'),
};

export const StyledColorBtnContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	position: 'relative',
	width: '90%',
	margin: '0 auto',
}));

export const StyledColorBtn = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, btnColor = 'yellow', disabled }) => ({
	backgroundImage: `url(${images[btnColor]})`,
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	backgroundSize: '100% 100%',
	minHeight: '70px',
	display: 'flex',
	alignItems: 'center',
	padding: '16px 20px',
	marginBottom: '10px',
	...(disabled && {
		opacity: '0.5',
	}),
}));

export const StyledCategoryTitle = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, color = 'yellow' }) => ({
	fontSize: '24px',
	textAlign: 'center',
	marginBottom: '10px',
}));
