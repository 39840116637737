import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { StyledBtn } from './Recruit.styles';

import configs from 'SRC/config';
import { StyledDialog } from 'SRC/Dialog.styles';
import { setReferralOpenAction } from 'SRC/store/game/game.reducer';
import { selectIsKOL, selectReferralCode, selectReferralOpen } from 'SRC/store/game/game.selector';

export default function RecruitDialog() {
	const referralCode = useSelector(selectReferralCode);
	const referralOpen = useSelector(selectReferralOpen);
	const isKOL = useSelector(selectIsKOL);
	const dispatch = useDispatch();
	const [isCopied, setIsCopied] = useState(false);

	const botUrl = `${configs.botUrl}${referralCode}`;
	let text;
	if (isKOL) {
		text = `🚨 Emergency Alert: Special Force Degen Recruitment%0A%0AYou are being recruited to fight against The Doom and save the Memes by enlisting immediately for the defense of Web3.%0A%0A🪖 You've been granted for an exclusive 💰 6,000 coin package with the invitation link for enlisting!%0A%0A🔫 Fight's on 🔥`;
	} else {
		text = `🚨 Emergency Alert: Urgent Degen Recruitment%0A%0AYou are being recruited to fight against The Doom and save the Memes by enlisting immediately for the defense of Web3.%0A%0A🪖 You've been granted for 💰 5,000 coins with the invitation link for enlisting!%0A%0A🔫 Fight's on 🔥`;
	}

	function handleInviteFriends() {
		window.open(`https://t.me/share/url?url=${botUrl}&text=${text}`);
	}

	function handleCopyReferralLink() {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(botUrl);
		} else {
			// fallback to old clipboard api
			const el = document.createElement('textarea');
			el.value = botUrl;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
			alert('Referral code copied to clipboard');
		}
		setIsCopied(true);
	}

	useEffect(() => {
		if (!referralOpen) {
			setIsCopied(false);
		}
	}, [referralOpen]);

	return (
		<StyledDialog show={Boolean(referralOpen)} open={Boolean(referralOpen)}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
				}}
			>
				<Box onClick={() => dispatch(setReferralOpenAction(false))}>
					<img
						style={{
							position: 'absolute',
							top: '20px',
							right: '20px',
						}}
						src={require('SRC/assets/images/dialog-cancel.png')}
						alt='Recruit Friends'
					/>
				</Box>

				<img
					style={{ width: '100px', height: '100px' }}
					src={require('SRC/assets/images/recruit-dialog-icon.png')}
					alt='Recruit Friends'
				/>
				<Box
					sx={{ fontSize: '24px', fontWeight: 600 }}
					className='strokeText'
					data-storke='Recruit Friends and'
				>
					Recruit Friends and
				</Box>
				<Box
					sx={{ fontSize: '24px', fontWeight: 600 }}
					className='strokeText'
					data-storke='get extra rewards!'
					mb='20px'
				>
					get extra rewards!
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', width: '100%' }}>
					<StyledBtn
						sx={{ flex: 1, padding: 0, width: '100%' }}
						onClick={() => handleInviteFriends()}
					>
						<Box
							sx={{
								fontSize: '16px',
								fontWeight: 600,
							}}
							className='strokeText'
							data-storke='Recruit frens'
						>
							Recruit frens
						</Box>
					</StyledBtn>
					<StyledBtn
						style={{ padding: 0 }}
						sx={{ flex: 1, flexShrink: 0, padding: 0, width: '100%' }}
						onClick={() => handleCopyReferralLink()}
					>
						<img src={require('SRC/assets/images/copy.svg').default} alt='copy' />
						{isCopied ? (
							<Box
								sx={{
									fontSize: '16px',
									fontWeight: 600,
									ml: '4px',
								}}
								className='strokeText'
								data-storke='Copied'
							>
								Copied
							</Box>
						) : (
							<Box
								sx={{
									fontSize: '16px',
									fontWeight: 600,
									ml: '4px',
								}}
								className='strokeText'
								data-storke='Copy'
							>
								Copy
							</Box>
						)}
					</StyledBtn>
				</Box>
			</Box>
		</StyledDialog>
	);
}
