import React, { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import {
	BrowserRouter,
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes,
} from 'react-router-dom';

import App from './App';
import AppContainer from './AppContainer';
import CustomThemeProvider from './CustomThemeProvider';

import ErrorDialogProvider from 'SRC/components/ErrorDialog/ErrorDialogProvider';
import { StyledIndexContainer } from 'SRC/index.styles';
import { store } from 'SRC/store/store';
import '@metacrm/metacrm-svg/dist/public/fonts/font-icon.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: true,
			refetchOnReconnect: true,
			retry: 3,
		},
	},
});

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		environment: process.env.REACT_APP_ENV,
		dsn: 'https://94299fb40e9b16258649c21964c393a7@o4504931933093888.ingest.us.sentry.io/4507933106569216',
		integrations: [
			Sentry.browserTracingIntegration({
				routingInstrumentation: Sentry.reactRouterV6BrowserTracingIntegration(
					useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes,
				),
			}),
			Sentry.replayIntegration({
				maskAllText: true,
				blockAllMedia: true,
			}),
		],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

root.render(
	<>
		<CustomThemeProvider>
			<Provider store={store}>
				<BrowserRouter>
					<StyledIndexContainer>
						<ErrorDialogProvider>
							<QueryClientProvider client={queryClient}>
								<AppContainer />
							</QueryClientProvider>
						</ErrorDialogProvider>
					</StyledIndexContainer>
				</BrowserRouter>
			</Provider>
		</CustomThemeProvider>
	</>,
);

if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		navigator.serviceWorker
			.register('/service-worker.js')
			.then((registration) => {
				console.log('Service Worker registered with scope:', registration.scope);
				if (registration.active) {
					console.log('Service worker is active');
				}
			})
			.catch((error) => {
				console.log('Service Worker registration failed:', error);
				Sentry.captureException(error);
			});
	});
}
