import { createSelector } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-unresolved

const selectGameReducer = (state) => state.game;

export const selectGameCoins = createSelector([selectGameReducer], (gameSlice) => gameSlice.coins);

export const selectGameATK = createSelector([selectGameReducer], (gameSlice) => gameSlice.ATK);

export const selectGameMonsterDamaged = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice?.monsterDamaged,
);
export const selectCurrentMonster = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice?.currentMonster,
);

export const selectNextMonster = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice?.nextMonster,
);

export const selectGameMonsterTotalBlood = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice?.monsterTotalBlood,
);

export const selectGameMonsterRemainedHealth = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice?.monsterRemainedHealth,
);

export const selectGameEnergy = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.energy,
);
export const selectGameMaxEnergy = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.maxEnergy,
);
export const selectGameClicks = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.clicks,
);
export const selectGameEnergyToReduce = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.energyToReduce,
);
export const selectGameEnergyToAdd = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.energyToAdd,
);

export const selectGameTotalDamageDealt = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.totalDamageDealt,
);

export const selectGameLevel = createSelector([selectGameReducer], (gameSlice) => {
	const levels = gameSlice.levelsRange || [];
	// console.log('levels: ', levels);
	const damage = gameSlice.totalDamageDealt;
	let level;

	for (let i = 0; i < levels.length; i += 1) {
		if (damage >= levels[i].min && damage < levels[i].max) {
			level = levels[i].level;
		}
	}
	return level;
});
export const selectRankNameMapping = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.rankName,
);

export const selectRankIconMapping = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.rankIcon,
);

export const selectSoldierAvatarMapping = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.soldierAvatar,
);

export const selectPrevRankName = createSelector(
	[selectGameLevel, selectRankNameMapping],
	(gameLevel, rankNameMapping) => rankNameMapping[gameLevel - 1],
);

export const selectRankName = createSelector(
	[selectGameLevel, selectRankNameMapping],
	(gameLevel, rankNameMapping) => rankNameMapping[gameLevel],
);

export const selectPrevRankIcon = createSelector(
	[selectGameLevel, selectRankIconMapping],
	(gameLevel, rankIconMapping) => rankIconMapping[gameLevel - 1],
);

export const selectRankIcon = createSelector(
	[selectGameLevel, selectRankIconMapping],
	(gameLevel, rankIconMapping) => rankIconMapping[gameLevel],
);

export const selectSoldierIcon = createSelector(
	[selectGameLevel, selectSoldierAvatarMapping],
	(gameLevel, soldierAvatarMapping) => soldierAvatarMapping[gameLevel],
);

export const selectGameMonsterList = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.monsterList,
);

export const selectLastMonster = createSelector([selectGameMonsterList], (monsterList) => {
	const end = monsterList.length - 1;
	return monsterList[end];
});

export const selectGameMonsterDefeatedNumber = createSelector(
	[selectGameMonsterList],
	(monsterList) => monsterList.filter((item) => item.defeated).length,
);

export const selectGameSkillList = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.skillList,
);

export const selectUserName = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.UserName,
);

export const selectFirstName = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.firstName,
);

export const selectLastName = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.lastName,
);

export const selectAllyValue = createSelector(
	[selectGameSkillList],
	(skillList) => skillList.find((item) => item.name === 'ALLY')?.value,
);

export const selectOfflineAllyDamage = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.offlineAllyDamage,
);

export const selectNoMoneyClicks = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.noMoneyClicks,
);

export const selectTelegramId = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.telegramId,
);

export const selectReferralCode = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.referralCode,
);

export const selectReferralOpen = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.referralOpen,
);

export const selectReferral = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.referral,
);

export const selectChangeMonsterIng = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.changeMonsterIng,
);

export const selectNeedToInit = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.needToInit,
);

export const selectTutorialFinished = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.tutorialFinished,
);

export const selectRecruitBarNotShow = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.recruitBarNotShow,
);

export const selectWalletAddress = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.walletAddress,
);

export const selectRecruitDot = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.redDot.recruit,
);

export const selectEarnRedDot = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.redDot.earn,
);

export const selectFrensInvited = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.frensInvited,
);

export const selectReferralRewardsSummed = createSelector(
	[selectGameReducer],
	(gameSlice) => gameSlice.referralRewardsSummed,
);

export const selectIsKOL = createSelector([selectGameReducer], (gameSlice) => gameSlice.isKOL);
