import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { attackMonsterAction, postMonsterKillAction } from 'SRC/store/game/game.reducer';
import { selectCurrentMonster, selectLastMonster } from 'SRC/store/game/game.selector';

export default function useVisibilityChange() {
	const dispatch = useDispatch();
	const currentMonster = useSelector(selectCurrentMonster);
	const lastMonster = useSelector(selectLastMonster);
	const isLastMonster = lastMonster?._id === currentMonster?._id;

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === 'visible') {
				if (!localStorage.getItem('lastRefresh')) return;
				if (new Date().getTime() - localStorage.getItem('lastRefresh') > 900000) {
					localStorage.removeItem('lastRefresh');
					window.location.reload();
				}
			} else if (document.visibilityState === 'hidden') {
				localStorage.setItem('lastRefresh', new Date().getTime());

				dispatch(attackMonsterAction({ isLastMonster, isByAttack: false }));
			}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, [dispatch, isLastMonster]);
}
