/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { find, get, isEmpty } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import StyledDialogBtn from 'SRC/components/Button/StyledDialogBtn';
import { setTutorialFinishedAction } from 'SRC/store/game/game.reducer';
import {
	StyledTutorialPageContainer,
	StyledTutorialContentArea,
	StyledSlider,
	StyledSlideContent,
} from 'SRC/TutorialPage.styles';
import { setStorage } from 'SRC/utils/storage';

function TutorialPage() {
	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
	};
	const dispatch = useDispatch();

	const tutorialArray = Array.from({ length: 6 }, (_, index) => index + 1);

	const handleSetTutorialFinished = () => {
		dispatch(setTutorialFinishedAction());
		setStorage('tutorialFinished', true);
	};

	return (
		<StyledTutorialPageContainer>
			<StyledTutorialContentArea>
				<StyledSlider {...settings}>
					{tutorialArray.map((item) => (
						<Box height='85%' key={item}>
							<StyledSlideContent>
								<Box
									component='img'
									width={item === tutorialArray.length ? '85%' : '100%'}
									src={require(`SRC/assets/images/t${item}.png`)}
								/>

								{item === tutorialArray.length && (
									<StyledDialogBtn
										width='120px'
										mt='170px'
										className='strokeText thick'
										onClick={handleSetTutorialFinished}
									>
										Start
									</StyledDialogBtn>
								)}
							</StyledSlideContent>
						</Box>
					))}
				</StyledSlider>
			</StyledTutorialContentArea>
		</StyledTutorialPageContainer>
	);
}

export default TutorialPage;
