import request from './base/request';

export function postAttack({
	monsterRemainedHealth,
	monsterId,
	damageDealt,
	coins,
	currentEnergy,
	timestamp,
}) {
	const config = {
		data: {
			monsterRemainedHealth,
			monsterId,
			damageDealt,
			coins,
			currentEnergy,
			timestamp,
		},
	};
	return request({
		url: '/api/game/attack',
		method: 'post',
		config,
		debounceTime: 500,
	});
}

export function postMonsterKill({ monsterId, timestamp }) {
	const config = {
		data: {
			monsterId,
			timestamp,
		},
	};
	return request({
		url: '/api/game/monsterKill',
		method: 'post',
		config,
		debounceTime: 200,
	});
}

export function postSkillUpdate({ skillId, coins }) {
	const config = {
		data: {
			skillId,
			coins,
		},
	};
	return request({
		url: '/api/game/upgradeSkill',
		method: 'post',
		config,
	});
}

export default {
	postAttack,
	postMonsterKill,
	postSkillUpdate,
};
