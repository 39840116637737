import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

import { StyledLoadingContainer } from './LoadingComponent.styles';

import loadingAnimation from 'SRC/assets/animation/loading.json';

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: loadingAnimation,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

function LoadingComponent({ sx, width = '210px', height = '260px' }) {
	return (
		<StyledLoadingContainer sx={sx}>
			<Lottie options={defaultOptions} width={width} height={height} />
		</StyledLoadingContainer>
	);
}
LoadingComponent.propTypes = {
	sx: PropTypes.shape({}),
	width: PropTypes.string,
	height: PropTypes.string,
};

LoadingComponent.defaultProps = {
	sx: {},
};

export default LoadingComponent;
