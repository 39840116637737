import styled from '@emotion/styled/macro';
import { Button } from '@metacrm/metacrm-material-ui/dist/Button';
import Box from '@mui/material/Box';

import isStyledPropsValid from 'SRC/utils/isStyledPropsValid';

export const StyledIndexContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	'& .strokeText': {
		position: 'relative',
		zIndex: 1,

		'&.yellow': {
			color: '#FFC203',
		},

		'&::before': {
			content: 'attr(data-storke)',
			position: 'absolute',
			zIndex: -1,
			WebkitTextStroke: '2px #000',
			textStroke: '2px #000',
		},
		'&.thick': {
			'&::before': {
				content: 'attr(data-storke)',
				position: 'absolute',
				zIndex: -1,
				WebkitTextStroke: '5px #000',
				textStroke: '5px #000',
			},
		},
	},
}));
