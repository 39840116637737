import React, { useState, useEffect } from 'react';

import Grid from '@metacrm/metacrm-material-ui/dist/Grid';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { postDailyCheckIn } from 'SRC/api/earn';
import { StyledDailyCheckInSingleDay } from 'SRC/pages/Earn/Earn.styles';
import { setAddCoinAction } from 'SRC/store/game/game.reducer';
import { selectGameCoins } from 'SRC/store/game/game.selector';
import { formatNumber } from 'SRC/utils/common';

function Daily({ daily, setDailyList, setTargetDaily, onLoad }) {
	const coins = useSelector(selectGameCoins);

	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const renderDailyBook = () => {
		if (daily.claimed) {
			return (
				<Box position='relative'>
					<Box
						sx={{ position: 'absolute', top: '36px', left: '50%', transform: 'translateX(-50%)' }}
					>
						<Box className='strokeText' data-storke={formatNumber(daily.reward)}>
							{formatNumber(daily.reward)}
						</Box>
					</Box>

					<Box
						component='img'
						mb='5px'
						width='58px'
						src={require(`SRC/assets/images/daily-open-book-check.png`)}
						onLoad={onLoad}
					/>
				</Box>
			);
		}
		if (!daily.enabled) {
			return (
				<Box
					component='img'
					mb='5px'
					width='35px'
					src={require(`SRC/assets/images/disable-book.png`)}
					onLoad={onLoad}
				/>
			);
		}

		return (
			<Box
				component='img'
				mb='5px'
				width='35px'
				src={require(`SRC/assets/images/able-book.png`)}
				onLoad={onLoad}
			/>
		);
	};

	const handlePostDailyCheckIn = async () => {
		if (isLoading) {
			return;
		}
		if (!daily.claimed && daily.enabled) {
			setIsLoading(true);
			try {
				const data = await postDailyCheckIn({ coins });
				const lastTenItems = data?.days?.slice(-10);
				setDailyList(lastTenItems);
				dispatch(setAddCoinAction(parseInt(daily.reward, 10)));
				setTargetDaily(daily);
			} catch (error) {
				console.error('error: ', error);
			} finally {
				setIsLoading(false);
			}
		}
	};

	return (
		<StyledDailyCheckInSingleDay onClick={() => handlePostDailyCheckIn()} isLoading={isLoading}>
			<Box className='strokeText' data-storke={`${daily.title}`} mb='5px' sx={{ fontSize: '12px' }}>
				{daily.title}
			</Box>
			{renderDailyBook()}
			{!daily.claimed && (
				<Box display='flex' alignContent='center'>
					<Box className='strokeText' data-storke={formatNumber(daily.reward)}>
						{formatNumber(daily.reward)}
					</Box>
					<Box component='img' width={16} height={16} src={require(`SRC/assets/images/coin.png`)} />
				</Box>
			)}
		</StyledDailyCheckInSingleDay>
	);
}

Daily.propTypes = {
	daily: PropTypes.shape({
		claimed: PropTypes.bool,
		enabled: PropTypes.bool,
		title: PropTypes.string,
		reward: PropTypes.number,
	}),
	setDailyList: PropTypes.func,
	setTargetDaily: PropTypes.func,
	onLoad: PropTypes.func,
};

export default Daily;
