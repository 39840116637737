/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { find, get, isEmpty } from 'lodash-es';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';

import { StyledVideoPageContainer } from './VideoPage.styles';

// import 'video-react/dist/video-react.css';

function VideoPage({ onNext }) {
	const handleSkip = () => {
		onNext();
	};

	return (
		<StyledVideoPageContainer>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ textAlign: 'right' }}>
					<Box
						sx={{ marginRight: '15px', display: 'inline-block', width: '95px' }}
						component='img'
						src={require('SRC/assets/images/skip_btn.png')}
						onClick={handleSkip}
					/>
				</Box>
				<Box>
					<ReactPlayer
						width='100%'
						height='auto'
						controls
						playing
						url={require('SRC/assets/videos/Onboard_Video.mp4')}
						muted
						playsinline
						onEnded={handleSkip}
					/>
				</Box>
			</Box>
		</StyledVideoPageContainer>
	);
}

export default VideoPage;
