import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import { clearIndexedDB } from './utils/apiRecallFromDB';

import {
	StyledEnergyBarContainer,
	StyledEnergyBarOutline,
	StyledEnergyBar,
} from 'SRC/components/EnergyBar/EnergyBar.styles';
import { StyledLoadingPageContainer } from 'SRC/LoadingPage.styles';

function LoadingPage({ progress }) {
	return (
		<StyledLoadingPageContainer>
			<Box>
				{/* <Box
					onClick={() => clearIndexedDB()}
					sx={{
						position: 'absolute',
						top: '0',
						left: '0',
						right: '0',
						width: '100%',
						textAlign: 'center',
						background: 'red',
					}}
				>
					Clean IndexDB
				</Box> */}
				<Box textAlign='center' mb='15px'>
					<Box component='img' width='216px' src={require('SRC/assets/images/logo_legion.png')} />
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						marginBottom: '50px',
					}}
				>
					<Box sx={{ fontWeight: 600 }} className='strokeText' data-storke='Loading...' mb='10px'>
						Loading...
					</Box>

					<Box position='relative' width='268px' height='25px'>
						<StyledEnergyBarOutline />
						<StyledEnergyBar style={{ width: `${progress}%` }} />
					</Box>
				</Box>
			</Box>
		</StyledLoadingPageContainer>
	);
}

LoadingPage.propTypes = {
	progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default LoadingPage;
