import request from './base/request';

export function fetchRecruitStats() {
	const config = {};
	return request({
		url: '/api/referral/stats',
		method: 'get',
		config,
	});
}

export function getCurrentReward() {
	const config = {};
	return request({
		url: '/api/referral/claim',
		method: 'post',
		config,
	});
}
