/* eslint-disable no-shadow */
import React, { useState, useRef, useContext, createContext, useCallback } from 'react';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import { StyledDialog, StyledDialogBtn } from './ErrorDialog.styles';

// Create Context
const ErrorPopup = createContext();

export const useErrorPopup = () => useContext(ErrorPopup);

// options 範例
// options = {
// 	errorText: '';
// };

function ErrorDialogProvider({ children }) {
	const [options, setOptions] = useState(null);
	const awaitingPromiseRef = useRef(null);

	const errorHandler = useCallback((options) => {
		if (options.noNeedConfirm) {
			return Promise.resolve();
		}
		setOptions(options);
		return new Promise((resolve, reject) => {
			awaitingPromiseRef.current = { resolve, reject };
		});
	}, []);

	const handleClose = () => {
		if (awaitingPromiseRef.current) {
			awaitingPromiseRef.current.reject();
		}
		setOptions(null);
	};

	const handleConfirm = () => {
		if (awaitingPromiseRef.current) {
			awaitingPromiseRef.current.resolve();
		}
		setOptions(null);
	};

	return (
		<>
			<ErrorPopup.Provider value={errorHandler}>
				{children}
				<StyledDialog open={Boolean(options)} show={Boolean(options)}>
					<Box className='strokeText' mb='20px'>
						{options?.errorText}
					</Box>
					<Box
						component='img'
						width='62px'
						mx='auto'
						mb='30px'
						src={require('SRC/assets/images/net-work-error.png')}
					/>
					<StyledDialogBtn
						className='strokeText thick'
						data-storke='Try Again'
						onClick={handleClose}
					>
						Try Again
					</StyledDialogBtn>
				</StyledDialog>
			</ErrorPopup.Provider>
		</>
	);
}

ErrorDialogProvider.propTypes = {
	children: PropTypes.node,
};

export default ErrorDialogProvider;
