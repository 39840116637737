import { useRoutes, Navigate } from 'react-router-dom';

import Earn from 'SRC/pages/Earn/Earn';
import Home from 'SRC/pages/Home/Home';
import Profile from 'SRC/pages/Profile/Profile';
import Recruit from 'SRC/pages/Recruit/Recruit';

function Routes() {
	const element = useRoutes([
		{ path: '/', element: <Home /> },
		{ path: '/profile', element: <Profile /> },
		{ path: '/earn', element: <Earn /> },
		{ path: '/recruit', element: <Recruit /> },

		// Add a wildcard route that redirects to home
		{ path: '*', element: <Navigate to='/' replace /> },
	]);
	return element;
}

export default Routes;
