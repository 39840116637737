import styled from '@emotion/styled/macro';
import { Button } from '@metacrm/metacrm-material-ui/dist/Button';
import Box from '@mui/material/Box';

import isStyledPropsValid from 'SRC/utils/isStyledPropsValid';

export const StyledProductButtonContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, isLoading }) => ({
	backgroundImage: `url(${require('SRC/assets/images/black-btn-vertical.png')})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100% 100%',
	width: '100%',
	// height: '215px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '8px 8px 15px',
	height: '190px',
	maxHeight: '190px',
	...(isLoading && {
		opacity: '0.5',
	}),
}));

export const StyledUpgradeBtn = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, noMoney, isLoading }) => ({
	fontSize: '13px',
	backgroundImage: `url(${require('SRC/assets/images/upgrade-btn.png')})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100% 100%',
	height: '54px',
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '0px 8px',
	...((noMoney || isLoading) && {
		opacity: '0.5',
	}),
}));
