import styled from '@emotion/styled/macro';
import { Button } from '@metacrm/metacrm-material-ui/dist/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import isStyledPropsValid from 'SRC/utils/isStyledPropsValid';

export const StyledDialog = styled(Dialog, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, show }) => ({
	width: '100%',
	'& .MuiPaper-root': {
		padding: '50px 35px',
		width: '90%',
		backgroundImage: `url(${require('SRC/assets/images/error_box.png')})`,
		backgroundSize: '100% 100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		backgroundColor: 'transparent',
		color: theme.customColors.white,
		textAlign: 'center',
		fontSize: '20px',
		opacity: 0,
		...(show && { opacity: 1 }),
	},
}));

const bgColorMap = {
	green: require('SRC/assets/images/green-block-btn.png'),
	yellow: require('SRC/assets/images/yellow-block-btn.png'),
};

export const StyledDialogBtn = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, bgColor = 'yellow' }) => ({
	minHeight: '50px',
	backgroundImage: `url(${bgColorMap[bgColor]})`,
	backgroundSize: '100% 100%',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center center',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '0 32px',
	fontSize: '20px',
	color: theme.customColors.white,
	width: 'fit-content',
	margin: '0 auto',
}));
