/* eslint-disable no-undef */
import React, { useState } from 'react';

import Box from '@mui/material/Box';
import { fontSize, height, minHeight } from '@mui/system';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
	StyledProfileContainerBackground,
	StyledProfileContainer,
	StyledLevelInfo,
	StyledPersonalInfo,
	StyledIconArea,
	StyledRankArea,
	StyledName,
	StyledAddress,
	StyledSingleIconItem,
	StyledIconItemTitle,
	StyledBlueBlockerContainer,
} from './Profile.styles';

import { postReset } from 'SRC/api/profile';
import StyledDialogBtn from 'SRC/components/Button/StyledDialogBtn';
import LoadingComponent from 'SRC/components/LoadingComponent/LoadingComponent';
import {
	selectGameLevel,
	selectUserName,
	selectFirstName,
	selectLastName,
	selectRankName,
	selectRankIcon,
	selectGameTotalDamageDealt,
	selectGameMonsterDefeatedNumber,
	selectSoldierIcon,
	selectWalletAddress,
	selectTelegramId,
	selectFrensInvited,
	selectReferralRewardsSummed,
} from 'SRC/store/game/game.selector';
import { removeStorage } from 'SRC/utils/storage';

function Profile(props) {
	const level = useSelector(selectGameLevel);
	const userName = useSelector(selectUserName);
	const firstName = useSelector(selectFirstName);
	const lastName = useSelector(selectLastName);
	const telegramId = useSelector(selectTelegramId);
	const rankName = useSelector(selectRankName);
	const rankIcon = useSelector(selectRankIcon);
	const soldierIcon = useSelector(selectSoldierIcon);
	const totalDamageDealt = useSelector(selectGameTotalDamageDealt);
	const monsterDefeatedNumber = useSelector(selectGameMonsterDefeatedNumber);
	const walletAddress = useSelector(selectWalletAddress);
	const frensInvited = useSelector(selectFrensInvited);
	const referralRewardsSummed = useSelector(selectReferralRewardsSummed);
	const [imagesLoaded, setImagesLoaded] = useState(0);

	const handleReset = () => {
		postReset();
		removeStorage('tutorialFinished');
		removeStorage('recruitBarNotShow');
	};

	const rankInfoItems = [
		{
			tltle: 'Total ATK',
			value: totalDamageDealt,
		},
		{
			tltle: 'Memes Saved',
			value: monsterDefeatedNumber,
			icon: require('SRC/assets/images/meme_saved.png'),
		},
		{
			tltle: 'Frens Invited',
			value: frensInvited,
		},
		{
			tltle: 'Rewards from Frens',
			value: referralRewardsSummed,
		},
		// {
		// 	tltle: 'Squad Rank',
		// 	value: 98,
		// },
		// {
		// 	tltle: 'World Rank',
		// 	value: 98,
		// },
	];

	const handleImageLoad = () => {
		setImagesLoaded((prevCount) => prevCount + 1);
	};

	function handleCopyWalletAddress() {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(walletAddress);
		} else {
			// fallback to old clipboard api
			const el = document.createElement('textarea');
			el.value = walletAddress;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
			alert('Referral code copied to clipboard');
		}
	}

	const renderName = () => {
		if (userName) {
			return (
				<Box className='strokeText' data-stroke={userName}>
					{userName}
				</Box>
			);
		}

		if (firstName && lastName) {
			return (
				<Box className='strokeText' data-stroke={`${firstName}_${lastName}`}>
					{firstName}_{lastName}
				</Box>
			);
		}
		return (
			<Box className='strokeText' data-stroke={`${telegramId}`}>
				{telegramId}
			</Box>
		);
	};

	return (
		<>
			{telegramId && (
				<StyledProfileContainerBackground>
					<Box
						sx={{
							transform: imagesLoaded < 1 ? 'scaleY(1)' : 'scaleY(0)',
							transformOrigin: 'top',
							overflow: 'hidden',
							height: imagesLoaded < 1 ? '100%' : 0,
						}}
					>
						<LoadingComponent />
					</Box>
					<StyledProfileContainer
						sx={{
							transform: imagesLoaded < 2 ? 'scaleY(0)' : 'scaleY(1)',
							transformOrigin: 'top',
							overflow: 'hidden',
						}}
					>
						<StyledLevelInfo>
							<Box className='strokeText' data-storke='PROFILE'>
								PROFILE
							</Box>
							<Box display='flex' alignItems='center' mt='5px'>
								<Box className='strokeText' data-storke='LV'>
									LV
								</Box>
								<Box className='strokeText' data-storke={level}>
									{level}
								</Box>
							</Box>
						</StyledLevelInfo>
						<StyledPersonalInfo>
							<Box
								component='img'
								src={soldierIcon}
								height='200px'
								sx={{
									margin: '0 auto',
									display: 'block',
								}}
								onLoad={handleImageLoad}
							/>
							<StyledName>{renderName()}</StyledName>
							<StyledAddress />
						</StyledPersonalInfo>

						<Box sx={{ width: '100%', padding: '0 20px' }}>
							{/* <Box sx={{ margin: '12px auto 0' }}>
								<StyledBlueBlockerContainer sx={{ alignItems: 'left', padding: '14px 20px' }}>
									<Box
										className='strokeText thick'
										data-storke='Sonic Wallet'
										marginBottom='5px'
										sx={{ fontSize: '16px' }}
									>
										Sonic Wallet
									</Box>
									<Box display='flex' alignItems='center'>
										<Box
											sx={{
												color: '#0F1221',
												background: '#FFFFFF',
												width: '100%',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap',
												display: 'flex',
												alignItems: 'center',
												fontWeight: '500',
												padding: '3px 7px',
												height: '28px',
											}}
										>
											<Box
												sx={{
													width: '100%',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													whiteSpace: 'nowrap',
												}}
											>
												{walletAddress}
											</Box>
										</Box>
										<StyledDialogBtn
											sx={{
												marginLeft: '8px',
												fontSize: '12px',
												minHeight: '32px',
												height: '32px',
											}}
											width='86px'
											className='strokeText thick'
											bgColor='yellow'
											onClick={() => handleCopyWalletAddress()}
										>
											Copy
										</StyledDialogBtn>
									</Box>
								</StyledBlueBlockerContainer>
							</Box> */}
							<StyledIconArea>
								<StyledSingleIconItem margin='20px auto 0'>
									<StyledBlueBlockerContainer
										sx={{ flexDirection: 'row', justifyContent: 'center' }}
									>
										<Box sx={{ position: 'absolute', top: '5px', left: '10px' }}>
											<Box
												className='strokeText thick'
												data-storke='MEDAL'
												sx={{ top: '10px', left: '10px', fontSize: '16px' }}
											>
												MEDAL
											</Box>
										</Box>

										<Box
											component='img'
											width={42}
											height={42}
											src={rankIcon}
											onLoad={handleImageLoad}
										/>
										<Box
											className='strokeText thick'
											data-storke={rankName}
											sx={{ fontSize: '22px' }}
										>
											{rankName}
										</Box>
									</StyledBlueBlockerContainer>
								</StyledSingleIconItem>
							</StyledIconArea>
						</Box>

						<StyledRankArea>
							{rankInfoItems.map((rankInfoItem, index) => (
								<Box
									display='flex'
									alignItems='center'
									justifyContent='space-between'
									key={index}
									mb='20px'
								>
									<Box className='strokeText' data-storke={rankInfoItem.tltle}>
										{rankInfoItem.tltle}
									</Box>

									<Box display='flex' alignItems='center'>
										<Box className='strokeText' data-storke={rankInfoItem.value}>
											{rankInfoItem.value}
										</Box>
									</Box>
								</Box>
							))}
						</StyledRankArea>
						{/* <Box
							backgroundColor='red'
							p='10px 20px'
							mt='auto'
							onClick={handleReset}
							textAlign='center'
						>
							reset
						</Box> */}
					</StyledProfileContainer>
				</StyledProfileContainerBackground>
			)}
		</>
	);
}

Profile.propTypes = {};

export default Profile;
