import { keyframes } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Button } from '@metacrm/metacrm-material-ui/dist/Button';
import Box from '@mui/material/Box';

import isStyledPropsValid from 'SRC/utils/isStyledPropsValid';

export const StyledLoadingContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, sx }) => ({
	height: '100%',
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	...sx,
}));
