/* eslint-disable no-unreachable */
import React, { useState, useEffect, useRef } from 'react';

import Grid from '@metacrm/metacrm-material-ui/dist/Grid';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/styles';
import { isEmpty } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import {
	StyledFloatNumber,
	StyledRipple,
	StyledHomeTopBar,
	StyledBottomInfoArea,
	StyledBossListContainer,
	StyledButtonAreaContainer,
	StyledInfoBlock,
	StyledHomeContainer,
	StyledShowMoneyAlert,
	StyledHomeGameArea,
	StyledHomeTopArea,
} from './Home.styles';

import { coin, eyeCoin, pepe, pepeCoin, devilPepe, lock, bossPepe } from 'SRC/assets/images';
import StyledDialogBtn from 'SRC/components/Button/StyledDialogBtn';
import ProductButton from 'SRC/components/Buttons/ProductButton';
import EnergyBar from 'SRC/components/EnergyBar/EnergyBar';
import Game from 'SRC/components/Game/Game';
import LoadingComponent from 'SRC/components/LoadingComponent/LoadingComponent';
import configs from 'SRC/config';
import { StyledDialog } from 'SRC/Dialog.styles';
import {
	attackMonsterAction,
	handleAnimationEndAction,
	handleAddEnergyAction,
	handleClearNoMoneyClicksAction,
	clearAllNoMoneyClicksAction,
	setReferralOpenAction,
	setRecruitBarNotShowAction,
} from 'SRC/store/game/game.reducer';
import {
	selectGameCoins,
	selectGameLevel,
	selectGameMonsterList,
	selectGameSkillList,
	selectCurrentMonster,
	selectPrevRankName,
	selectPrevRankIcon,
	selectRankName,
	selectRankIcon,
	selectGameMaxEnergy,
	selectNoMoneyClicks,
	selectRecruitBarNotShow,
	selectReferralCode,
} from 'SRC/store/game/game.selector';
import { setStorage } from 'SRC/utils/storage';

function Home() {
	const coins = useSelector(selectGameCoins);
	const currentMonster = useSelector(selectCurrentMonster);
	const level = useSelector(selectGameLevel);
	const monsterList = useSelector(selectGameMonsterList);
	const skillList = useSelector(selectGameSkillList);
	const rankIcon = useSelector(selectRankIcon);
	const prevRankIcon = useSelector(selectPrevRankIcon);
	const rankName = useSelector(selectRankName);
	const prevRankName = useSelector(selectPrevRankName);
	const maxEnergy = useSelector(selectGameMaxEnergy);
	const noMoneyClicks = useSelector(selectNoMoneyClicks);
	const recruitBarNotShow = useSelector(selectRecruitBarNotShow);
	const referralCode = useSelector(selectReferralCode);
	const [imagesLoaded, setImagesLoaded] = useState(0);
	const [waitForGameFinished, setWaitForGameFinished] = useState(false);
	const [showRankIconUpdateAlert, setShowRankIconUpdateAlert] = useState(false);
	const isMounted = useRef(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const theme = useTheme();
	const routes = [
		{ name: 'profile', to: '/profile' },
		{ name: 'earn' },
		{ name: 'frens' },
		{ name: 'ranking' },
	];

	useEffect(() => {
		if (isMounted.current) {
			setShowRankIconUpdateAlert(true);
		} else {
			isMounted.current = true;
		}
	}, [rankIcon]);

	const unLockMonsterNumber = monsterList.filter((monster) => !monster.locked).length;

	const lastUnlockedIndex = monsterList
		.map((monster, index) => (monster.locked ? -1 : index))
		.filter((index) => index !== -1)
		.pop();

	useEffect(
		() => () => {
			dispatch(clearAllNoMoneyClicksAction());
		},
		[],
	);

	const handleImageLoad = () => {
		setImagesLoaded((prevCount) => prevCount + 1);
	};

	useEffect(() => {
		setTimeout(() => {
			setWaitForGameFinished(true);
		}, 0);
	}, []);

	const handleTORecruit = () => {
		navigate('/recruit');
		dispatch(setRecruitBarNotShowAction());
		setStorage('recruitBarNotShow', true);
	};

	const handleShareOnTwitter = () => {
		const botUrl = `${configs.botUrl}${referralCode}`;
		const text = `I've just ranked up to "${rankName}" ! Let's save the Memes in @LegionMemeWorld together—join the adventure and take on the challenge! Jump right in:`;
		const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(botUrl)}`;
		window.open(twitterUrl, '_blank', 'noopener,noreferrer');
	};

	const renderGame = () => {
		if (!isEmpty(currentMonster)) {
			return <Game />;
		}
		return <LoadingComponent width='180px' height='220px' />;
	};
	return (
		<StyledHomeContainer>
			<StyledHomeGameArea>
				{!recruitBarNotShow && (
					<StyledHomeTopBar onClick={handleTORecruit}>
						<Box
							className='strokeText thick'
							data-storke='Recruit to earn'
							color='#F3E87F'
							mr='8px'
							sx={{
								whiteSpace: 'nowrap',
							}}
						>
							Recruit to earn
						</Box>
						<Box display='flex' alignItems='center'>
							<Box
								sx={{
									background:
										'linear-gradient(180deg, #FFF9EC 27.59%, #FDAB00 53.45%, #976600 79.31%)',
									backgroundClip: 'text',
									WebkitBackgroundClip: 'text',
									color: 'transparent',
									whiteSpace: 'nowrap',
								}}
								className='strokeText'
								data-storke='50M Rewards'
							>
								50M Rewards
							</Box>
							<Box
								component='img'
								src={require(`SRC/assets/images/coin.png`)}
								width={40}
								height={40}
								mr='7px'
								ml='7px'
							/>
						</Box>

						<Box
							component='i'
							color='#612218'
							className='meta-crm-icon-ic_arrow_pixel font-size-16'
						/>
					</StyledHomeTopBar>
				)}

				<StyledHomeTopArea width='100%' topHasSomething={!recruitBarNotShow}>
					<Box display='flex' alignItems='center' justifyContent='space-between' padding='5px'>
						<Box>
							<StyledInfoBlock blockColor='blue'>
								<Box component='img' width={20} height={20} src={rankIcon} />
								<Box
									sx={{
										'&:after': {
											display: 'block',
											content: '" "',
											width: '1px',
											height: '14px',
											backgroundColor: '#0F1221',
											marginRight: '6px',
											marginLeft: '5px',
										},
									}}
								/>
								<Box className='strokeText' data-storke='LV' mr='5px'>
									LV
								</Box>

								<Box className='strokeText' data-storke={level}>
									{level}
								</Box>
							</StyledInfoBlock>
						</Box>

						<StyledInfoBlock>
							<Box component='img' src={eyeCoin} width={17} height={17} mr={2} />

							<Box fontSize='14px' className='strokeText' data-storke={coins.toLocaleString()}>
								{coins.toLocaleString()}
							</Box>
						</StyledInfoBlock>
					</Box>
				</StyledHomeTopArea>

				<Box
					id='game-container'
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
						// minHeight: '400px',
					}}
				>
					{/* {!isEmpty(currentMonster) && <Game />} */}
					{renderGame()}
				</Box>
			</StyledHomeGameArea>

			{maxEnergy > 0 && (
				<StyledBottomInfoArea>
					<StyledBossListContainer>
						{monsterList.map((monster, index) => (
							<Box
								component='img'
								src={monster.locked ? lock : monster?.imagesBefore}
								onLoad={monster.locked ? null : handleImageLoad}
								key={index}
								width='30px'
								height='30px'
								mr={index !== monsterList.length - 1 ? '8px' : 0}
								sx={{
									border: !monster.locked ? '1px solid #FDCF3D' : 'none',
									borderRadius: '50%',
									...(lastUnlockedIndex === index && {
										border: '1px solid #FD0000',
									}),
								}}
							/>
						))}
					</StyledBossListContainer>
					<EnergyBar />

					{noMoneyClicks.map((click) => (
						<StyledShowMoneyAlert
							key={click.id}
							onAnimationEnd={() => dispatch(handleClearNoMoneyClicksAction(click.id))}
						>
							Need more coins
						</StyledShowMoneyAlert>
					))}

					<StyledButtonAreaContainer>
						{skillList
							.filter((skill) => skill.name !== 'MISSILE')
							.map((skill) => (
								<Box mb='5px' key={skill.category}>
									<ProductButton {...skill} />
								</Box>
							))}
					</StyledButtonAreaContainer>
				</StyledBottomInfoArea>
			)}

			<StyledDialog open={showRankIconUpdateAlert} className='test' show={showRankIconUpdateAlert}>
				<Box
					sx={{ fontSize: '24px', textAlign: 'center', marginBottom: '10px' }}
					className='strokeText'
					data-storke='Congratulations!'
				>
					Congratulations!
				</Box>

				<Box sx={{ fontSize: '16px', textAlign: 'center' }}>
					You&#39;ve been promoted to the next rank!
				</Box>

				<Box display='flex' alignItems='center' justifyContent='center'>
					<Box display='flex' flexDirection='column' alignItems='center' mr='10px'>
						<Box component='img' width={100} height={100} src={prevRankIcon} />
						<Box>{prevRankName}</Box>
					</Box>
					<Box
						component='img'
						width={47}
						height={24}
						src={require(`SRC/assets/images/next_level.png`)}
					/>
					<Box display='flex' flexDirection='column' alignItems='center' mr='10px'>
						<Box component='img' width={100} height={100} src={rankIcon} />
						<Box>{rankName}</Box>
					</Box>
				</Box>

				<Box display='flex' justifyContent='space-around' mt='44px'>
					<StyledDialogBtn
						width='120px'
						className='strokeText'
						onClick={() => {
							handleShareOnTwitter();
						}}
					>
						Share
					</StyledDialogBtn>
					<StyledDialogBtn
						bgColor='yellow'
						width='120px'
						className='strokeText'
						data-storke='Close'
						onClick={() => {
							setShowRankIconUpdateAlert(false);
						}}
					>
						Close
					</StyledDialogBtn>
				</Box>
			</StyledDialog>
		</StyledHomeContainer>
	);
}

export default Home;
