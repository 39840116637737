import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';

import isStyledPropsValid from 'SRC/utils/isStyledPropsValid';

export const StyledRecruitText = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ sx }) => ({
	textShadow: '0px 0.914px 0px #000, 0px 0.914px 0px #000',
	'-webkit-text-stroke-width': 2,
	'-webkit-text-stroke-color': '#0F1221',
	fontFamily: 'Saira',
	fontSize: '34px',
	fontWeight: 700,
	lineHeight: 'normal',
	...sx,
}));

export const StyledRecruitBtn = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ sx }) => ({
	backgroundImage: `url(${require('SRC/assets/images/recruit-invite-btn.png')})`,
	backgroundSize: '100% 100%',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center center',
	backgroundColor: 'transparent',
}));

export const StyledFriendsBtn = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ sx }) => ({
	backgroundImage: `url(${require('SRC/assets/images/recruit-friend-btn.png')})`,
	backgroundSize: '100% 100%',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center center',
	backgroundColor: 'transparent',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: '14px 22px',
	margin: '5px 0',
}));

const bgColorMap = {
	green: require('SRC/assets/images/green-block-btn.png'),
	yellow: require('SRC/assets/images/yellow-block-btn.png'),
};

export const StyledBtn = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, bgColor = 'green', sx }) => ({
	minHeight: '50px',
	backgroundImage: `url(${bgColorMap[bgColor]})`,
	backgroundSize: '100% 100%',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center center',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '0 32px',
	fontSize: '20px',
	color: theme.customColors.white,
	...sx,
}));
