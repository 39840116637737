import styled from '@emotion/styled/macro';
import { Button } from '@metacrm/metacrm-material-ui/dist/Button';
import Box from '@mui/material/Box';

import isStyledPropsValid from 'SRC/utils/isStyledPropsValid';

export const StyledEnergyBarContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	// background: '#132A5C',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	padding: '8.75px 14px',
	// boxShadow: `inset -3px 2px #2252DA`,
	// borderBottom: '2px solid #000',
	// borderLeft: '2px solid #000',
}));

export const StyledEnergyBarOutline = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	backgroundImage: `url(${require('SRC/assets/images/energyBarContainer.png')})`,
	// border: '2px solid white',
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100% 100%',
	flex: 1,
	height: '22px',
	borderRadius: '24px',
	position: 'absolute',
	left: 0,
	right: 0,
	top: '50%',
	transform: 'translateY(-50%)',
	zIndex: 2,
}));

export const StyledEnergyBar = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	height: '14px',
	borderRadius: '20px',
	background: '#ffd140',
	boxShadow: `inset 1px -7px #FFBC21`,
	position: 'absolute',
	left: '4px',
	right: '4px',
	top: '50%',
	transform: 'translateY(-50%)',
	zIndex: 1,
}));
