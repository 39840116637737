import request from './base/request';

export function fetchDailyCheckIn() {
	return request({
		url: '/api/earn/dailyCheckIn',
		method: 'get',
	});
}

export function postDailyCheckIn({ coins }) {
	const config = {
		data: {
			coins,
		},
	};
	return request({
		url: '/api/earn/dailyCheckIn',
		method: 'post',
		config,
		debounceTime: 200,
	});
}

export function fetchBooster() {
	return request({
		url: '/api/earn/booster',
		method: 'get',
	});
}

export function postBooster() {
	return request({
		url: '/api/earn/booster',
		method: 'post',
	});
}

export function fetchLevelReward({ damageDealt }) {
	const config = {
		params: {
			damageDealt,
		},
	};
	return request({
		url: '/api/earn/levelReward',
		method: 'get',
		config,
	});
}

export function postLevelReward({ coins, damageDealt }) {
	const config = {
		data: {
			coins,
			damageDealt,
		},
	};
	return request({
		url: '/api/earn/levelReward',
		method: 'post',
		config,
	});
}

export function fetchMissions() {
	return request({
		url: '/api/earn/missions',
		method: 'get',
	});
}

export function postEarnMission({ coins, status, _id }) {
	const config = {
		data: {
			coins,
			_id,
			status,
		},
	};
	return request({
		url: '/api/earn/missions',
		method: 'post',
		config,
		debounceTime: 500,
	});
}

export default {
	fetchDailyCheckIn,
	postDailyCheckIn,
	fetchBooster,
	postBooster,
	fetchLevelReward,
	postLevelReward,
	fetchMissions,
	postEarnMission,
};

// api文件:https://www.notion.so/metacrm/1246b327d7874ad997fca12fc5863681?v=796fa1c57fba41db8483abf1089d4fc3&p=431f2542c7fd48df87580df4444ef998&pm=s
