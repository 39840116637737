import styled from '@emotion/styled/macro';
import { Button } from '@metacrm/metacrm-material-ui/dist/Button';
import Box from '@mui/material/Box';
import { yellow } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';

import isStyledPropsValid from 'SRC/utils/isStyledPropsValid';

const images = {
	blue: require('SRC/assets/images/popup.png'),
	black: require('SRC/assets/images/popup_black.png'),
};

export const StyledDialog = styled(Dialog, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, show, bgColor = 'black' }) => ({
	width: '100%',
	'& .MuiPaper-root': {
		padding: '50px 35px',
		width: '90%',
		backgroundImage: `url(${images[bgColor]})`,
		backgroundSize: '100% 100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		backgroundColor: 'transparent',
		opacity: 0,
		color: theme.customColors.white,
		...(show && { opacity: 1 }),
	},
}));

const bgColorMap = {
	green: require('SRC/assets/images/green-block-btn.png'),
	yellow: require('SRC/assets/images/yellow-block-btn.png'),
};

export const StyledDialogBtn = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, bgColor = 'green', isLoading, sx }) => ({
	minHeight: '50px',
	backgroundImage: `url(${bgColorMap[bgColor]})`,
	backgroundSize: '100% 100%',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center center',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '0 32px',
	fontSize: '20px',
	color: theme.customColors.white,
	...(isLoading && { opacity: 0.5 }),
	...sx,
}));
