/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { find, get, isEmpty } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import StyledDialogBtn from 'SRC/components/Button/StyledDialogBtn';
import { StyledMobileOnlyPageContainer } from 'SRC/MobileOnlyPage.styles';
import { setTutorialFinishedAction } from 'SRC/store/game/game.reducer';
import { setStorage } from 'SRC/utils/storage';

function MobileOnlyPage() {
	const dispatch = useDispatch();

	return (
		<StyledMobileOnlyPageContainer>
			<Box
				component='img'
				width='60%'
				mt='30px'
				src={require(`SRC/assets/images/play_mobile_top.png`)}
			/>
			<Box component='img' width='60%' mt='30px' src={require(`SRC/assets/images/top_title.png`)} />
			<Box
				component='img'
				width='60%'
				marginTop='20px'
				src={require(`SRC/assets/images/mobile_qrcode.png`)}
			/>
			<Box
				component='img'
				width='60%'
				mt='30px'
				src={require(`SRC/assets/images/bottom_title.png`)}
			/>
			<Box
				component='img'
				width='80%'
				marginTop='auto'
				src={require(`SRC/assets/images/play_mobile_bottom.png`)}
			/>
		</StyledMobileOnlyPageContainer>
	);
}

export default MobileOnlyPage;
