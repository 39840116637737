import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { handleAddEnergyAction, handleAllyAction } from 'SRC/store/game/game.reducer';
import { selectGameMonsterRemainedHealth, selectAllyValue } from 'SRC/store/game/game.selector';

function SetIntervalComponent() {
	const dispatch = useDispatch();
	const monsterRemainedHealth = useSelector(selectGameMonsterRemainedHealth);
	const allyValue = useSelector(selectAllyValue);

	useEffect(() => {
		// 每1秒增加點能量
		const interval = setInterval(() => {
			dispatch(handleAddEnergyAction());
			// if (allyValue) {
			// 	dispatch(handleAllyAction());
			// }
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		// 每1秒增加傷害
		const allyInterval = setInterval(() => {
			if (allyValue > 0 && monsterRemainedHealth > 0) {
				dispatch(handleAllyAction());
			}
		}, 1000);

		return () => clearInterval(allyInterval);
	}, [allyValue, monsterRemainedHealth]);

	return <></>;
}

export default SetIntervalComponent;
