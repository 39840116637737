import styled from '@emotion/styled/macro';
import { Button } from '@metacrm/metacrm-material-ui/dist/Button';
import Box from '@mui/material/Box';

import isStyledPropsValid from 'SRC/utils/isStyledPropsValid';

export const StyledApp = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	position: 'relative',
	color: theme.customColors.white,
	fontWeight: 700,
	lineHeight: 1,
	height: '100dvh',
	display: 'flex',
	flexDirection: 'column',
	maxWidth: '450px',
	margin: '0 auto',
}));

export const StyledRoutesContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	flex: 1,
	overflow: 'auto',
}));

export const StyledRouterButtons = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	padding: '5px 5px 10px',
	background: '#7979FF',
	boxShadow: `inset 0px 4px #ABA4FF, inset 0px -4px #151A2F`,
	zIndex: 5,
}));

const images = {
	profile: require('SRC/assets/images/menu_profile.png'),
	earn: require('SRC/assets/images/menu_earn.png'),
	recruit: require('SRC/assets/images/menu_recruit.png'),
	ranking: require('SRC/assets/images/menu_ranking.png'),
	fight: require('SRC/assets/images/menu_fight.png'),
};

export const StyledRouterButton = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, routeName, active, disabled, redDot }) => ({
	display: 'flex',
	backgroundImage: `url(${images[routeName]})`,
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	backgroundSize: '100% 100%',
	height: '60px',
	width: '67px',
	position: 'relative',
	...(active && {
		backgroundColor: '#151A2F',
	}),
	...(disabled && {
		opacity: 0.5,
	}),
	...(redDot && {
		'&::after': {
			content: '""',
			position: 'absolute',
			top: '4px',
			right: '15px',
			height: '10px',
			width: '10px',
			backgroundColor: 'red',
			borderRadius: '50%',
		},
	}),
}));
