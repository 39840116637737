import React from 'react';

import ColorModeContext from '@metacrm/metacrm-material-ui/dist/contexts/ColorMode.context';
import useColorMode from '@metacrm/metacrm-material-ui/dist/hooks/useColorMode.hooks';
import { CssBaseline, ThemeProvider, GlobalStyles } from '@mui/material';
import { createTheme, alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

function CustomThemeProvider(props) {
	const { colorModeHooksValue, theme } = useColorMode();

	const newThemeSetting = {
		...theme,
		palette: {
			...theme.palette,
			background: { default: '#eeeeee' },
		},
		components: {
			MuiButton: {},
		},
		typography: {
			fontFamily: `Saira, sans-serif, 'Noto Sans CJK TC', '微軟正黑體'`,
		},
	};

	const newTheme = createTheme(newThemeSetting);

	const globalStyle = {
		a: {
			textDecoration: 'none',
		},
		'@font-face': [
			{
				fontFamily: 'Saira',
				src: `url(${require('SRC/fonts/Saira-Light.ttf')}) format('truetype')`,
				fontWeight: 300,
			},
			{
				fontFamily: 'Saira',
				src: `url(${require('SRC/fonts/Saira-Regular.ttf')}) format('truetype')`,
				fontWeight: 500,
			},
			{
				fontFamily: 'Saira',
				src: `url(${require('SRC/fonts/Saira-Bold.ttf')}) format('truetype')`,
				fontWeight: 700,
			},
			{
				fontFamily: 'Saira',
				src: `url(${require('SRC/fonts/Saira-Black.ttf')}) format('truetype')`,
				fontWeight: 900,
			},
		],
	};

	return (
		<ColorModeContext.Provider value={colorModeHooksValue}>
			<ThemeProvider theme={newTheme}>
				<CssBaseline />
				<GlobalStyles styles={globalStyle} />
				{props.children}
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
}

CustomThemeProvider.propTypes = {
	children: PropTypes.node,
};

export default CustomThemeProvider;
