import styled from '@emotion/styled/macro';
import { Button } from '@metacrm/metacrm-material-ui/dist/Button';
import Box from '@mui/material/Box';
import { yellow } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import Slider from 'react-slick';

import isStyledPropsValid from 'SRC/utils/isStyledPropsValid';

const images = {
	blue: require('SRC/assets/images/popup.png'),
	black: require('SRC/assets/images/popup_black.png'),
};

export const StyledTutorialPageContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	minHeight: '100dvh',
	background: '#0F1221',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
}));

export const StyledTutorialContentArea = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	background: 'linear-gradient(180deg, #0F1221 0%, #151A2F 75.5%, #43957C 100%)',
	minHeight: '100dvh',
	width: '100%',
}));

export const StyledSlider = styled(Slider, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	height: '100dvh',
	'& .slick-list': {
		height: '100%',
		'& .slick-track': {
			height: '100%',

			'& .slick-slide': {
				height: '100%',

				'& > div': {
					height: '100%',
				},
			},
		},
	},

	'& .slick-dots': {
		bottom: '45px',

		'& li': {
			width: '12px',
			'&.slick-active': {
				'& button': {
					'&:before': {
						color: '#82DA13',
					},
				},
			},
			'& button': {
				width: '12px',

				'&:before': {
					fontSize: '10px',
					color: '#1B2939',
					opacity: '1',
				},
			},
		},
	},
}));

export const StyledSlideContent = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	overflow: 'hidden',
	paddingTop: '15px',
}));
