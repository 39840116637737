import * as Sentry from '@sentry/react';
import axios from 'axios';
import { debounce } from 'lodash-es';

import authorization from './authorization';
import { generateCustomNonce, generateHMAC } from './crypto';

// import { apiUrl } from 'SRC/features/configure';
import apiConfig from 'SRC/config';
import { saveRequest } from 'SRC/utils/apiRecallFromDB';

const baseURL = apiConfig.apiUrl;
const debouncedRequests = {};
const notNeedSHA256 = ['/api/users/login', '/api/game/mappings', '/api/referral/code'];

export default ({ method, url, config = {}, debounceTime }) => {
	const key = `${url}_${method}`;

	if (!debouncedRequests[key]) {
		debouncedRequests[key] = {
			debounceFunction: null,
			debounceTime: null,
		};
	}

	const makeRequest = (resolve, reject, newConfig) => {
		let finalConfig;

		if (!notNeedSHA256.includes(url)) {
			const nonce = generateCustomNonce(16);
			const timestamp = Date.now();
			const signature = generateHMAC({ data: newConfig.data, nonce, timestamp });
			const finalAuth = {
				headers: {
					...authorization().headers,
					'X-Signature': signature,
					'X-Nonce': nonce,
					'X-timestamp': timestamp,
				},
			};

			finalConfig = {
				...finalAuth,
				...newConfig,
			};
		} else {
			finalConfig = {
				...authorization(),
				...newConfig,
			};
		}

		axios({ baseURL, method, url, ...finalConfig })
			.then((response) => {
				if (response.status === 200) {
					resolve(response.data);
				} else {
					throw response?.data?.message || response;
				}
			})
			.catch((error) => {
				Sentry.captureException(error);

				const needSaveApi = ['/api/game/attack'];

				if (!('serviceWorker' in navigator) && needSaveApi.includes(url)) {
					// Service Worker not supported, save the failed request to IndexedDB

					saveRequest(
						baseURL + url,
						finalConfig.headers.Authorization,
						JSON.stringify(finalConfig.data),
					)
						.then(() => console.log('Request saved to IndexedDB'))
						.catch((dbError) => {
							Sentry.captureException(error);
							console.log('Failed to save request to IndexedDB:', dbError);
						});
				}

				reject(error?.response?.data || error);
			});
	};

	// Initialize debounced request if not exist or debounceTime has changed
	if (!debouncedRequests[key] || debouncedRequests[key].debounceTime !== debounceTime) {
		debouncedRequests[key] = {
			debounceFunction: debounceTime ? debounce(makeRequest, debounceTime) : makeRequest,
			debounceTime,
		};
	}

	return new Promise((resolve, reject) => {
		debouncedRequests[key].debounceFunction(resolve, reject, config);
	});
};
