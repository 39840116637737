import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
	StyledEnergyBarContainer,
	StyledEnergyBarOutline,
	StyledEnergyBar,
} from './EnergyBar.styles';

import { selectGameEnergy, selectGameMaxEnergy } from 'SRC/store/game/game.selector';

function EnergyBar() {
	const energy = useSelector(selectGameEnergy);
	const maxEnergy = useSelector(selectGameMaxEnergy);

	return (
		<StyledEnergyBarContainer>
			<Box display='flex' alignItems='center'>
				<Box
					component='img'
					src={require('SRC/assets/images/thunder.png')}
					sx={{ width: '16px' }}
					mr='5px'
				/>
			</Box>
			<Box position='relative' flex={1}>
				<StyledEnergyBarOutline />
				<StyledEnergyBar style={{ width: `${(energy / maxEnergy) * 97.5}%` }} />
				<Box
					display='flex'
					alignItems='center'
					justifyContent='center'
					flex={1}
					position='absolute'
					sx={{
						left: '50%',
						top: '50%',
						transform: 'translate(-50%, -50%)',
						zIndex: 1,
					}}
				>
					<Box className='strokeText' data-storke={energy}>
						{energy}
					</Box>
					<Box className='strokeText' data-storke='/' mr='2px' ml='2px'>
						/
					</Box>
					<Box className='strokeText' data-storke={maxEnergy}>
						{maxEnergy}
					</Box>
				</Box>
			</Box>
		</StyledEnergyBarContainer>
	);
}

EnergyBar.propTypes = {};

export default EnergyBar;
