import { keyframes } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Button } from '@metacrm/metacrm-material-ui/dist/Button';
import Box from '@mui/material/Box';

import isStyledPropsValid from 'SRC/utils/isStyledPropsValid';

export const StyledProfileContainerBackground = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	// backgroundImage: `url(${require('SRC/assets/images/profile-background.png')})`,
	background: 'linear-gradient(180deg, #0F1221 0%, #151A2F 82.5%, #43957C 100%)',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'top center',
	height: '100%',
}));

export const StyledProfileContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	backgroundImage: `url(${require('SRC/assets/images/treasure-bg.png')})`,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'top center',
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	color: theme.customColors.white,
	height: '100%',
	transition: 'all 0.2s ease',
}));

export const StyledLevelInfo = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	height: '89px',
	width: '206px',
	backgroundImage: `url(${require('SRC/assets/images/yellowFlag-profile.png')})`,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center center',
	margin: '0 auto',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	fontSize: '22px',
	fontWeight: 900,
	paddingTop: '12px',
}));

export const StyledPersonalInfo = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledName = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	textAlign: 'center',
	fontWeight: 800,
	fontSize: '36px',
	marginTop: '15px',
}));

export const StyledAddress = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	textAlign: 'center',
	fontWeight: 800,
	fontSize: '20px',
}));

export const StyledIconArea = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledSingleIconItem = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledIconItemTitle = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	fontSize: '20px',
	fontWeight: 800,
	textAlign: 'center',
}));

export const StyledBlueBlockerContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme, sx }) => ({
	background: '#7979FF',
	fontSize: '22px',
	boxShadow: `inset 0px 4px #ABA4FF`,
	width: '100%',
	height: '80px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '10px 15px',
	position: 'relative',
	...sx,
}));

export const StyledRankArea = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	marginTop: '30px',
	padding: '0 20px',
	fontSize: '24px',
	fontWeight: 800,
}));
