import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';

import isStyledPropsValid from 'SRC/utils/isStyledPropsValid';

export const StyledMobileOnlyPageContainer = styled(Box, {
	shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
	backgroundImage: `url(${require('SRC/assets/images/play_mobile_bg.png')})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	height: '100dvh',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	color: 'white',
}));
