import React, { useRef, useEffect } from 'react';

export default function usePlaySound(key) {
	const sounds = {
		skill: require('SRC/assets/audios/skill.wav'),
		character: require('SRC/assets/audios/character.mp3'),
	};

	const clickSoundRef = useRef(null);

	useEffect(() => {
		clickSoundRef.current = new Audio(sounds[key]);
	}, [key]);

	// 点击事件处理函数
	const playSound = () => {
		if (clickSoundRef.current) {
			clickSoundRef.current.volume = 0.08;
			clickSoundRef.current.play();
		}
	};

	return playSound;
}
