/* eslint-disable no-unreachable */
import React, { useState, useEffect, useMemo, useRef } from 'react';

import Box from '@mui/material/Box';
import { find, get } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import App from './App';
import useVisibilityChange from './hooks/useVisibilityChange';
import LoadingPage from './LoadingPage';
import MobileOnlyPage from './MobileOnlyPage';
import Onboarding from './Onboarding';
import sendOfflinePostRequestsToServer from './utils/apiRecallFromDB';

import usePlaySound from 'SRC/hooks/usePlaySound';
import { loginAction } from 'SRC/store/game/game.reducer';
import {
	selectFirstName,
	selectGameLevel,
	selectTutorialFinished,
	selectTelegramId,
} from 'SRC/store/game/game.selector';
import { detectDevice } from 'SRC/utils/common';
import { getStorage } from 'SRC/utils/storage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function AppContainer() {
	const level = useSelector(selectGameLevel);
	const [progress, setProgress] = useState(0);
	const [loadingFinished, setLoadingFinished] = useState(false);
	useVisibilityChange();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const firstName = useSelector(selectFirstName);
	const tutorialFinished = useSelector(selectTutorialFinished);
	const telegramId = useSelector(selectTelegramId);

	const firstRender = useRef(true); // 初始值设为 true，表示首次渲染
	const playSound = usePlaySound('character');

	useEffect(() => {
		if (!level) return;
		if (!firstRender.current) {
			playSound();
		} else {
			firstRender.current = false; // 标记已经过了首次渲染
		}
	}, [level]);

	useEffect(() => {
		if (window.Telegram.WebApp) {
			if (location.pathname !== '/') {
				window.Telegram.WebApp.BackButton.show();
				window.Telegram.WebApp.BackButton.onClick(() => {
					navigate('/');
				});
			} else {
				window.Telegram.WebApp.BackButton.hide();
			}
		}
	}, [location.pathname]);

	useEffect(() => {
		// dispatch(
		// 	loginAction({
		// 		telegramId: 6626587174,
		// 		firstName: 'Clifford',
		// 		lastName: 'Tien',
		// 		languageCode: 'zh-hans',
		// 		inviteCode: 'lmvg23',
		// 		initData:
		// 			'query_id=AAEmsvkKAwAAACay-Qo4yH1w&user=%7B%22id%22%3A6626587174%2C%22first_name%22%3A%22Clifford%22%2C%22last_name%22%3A%22Tien%22%2C%22language_code%22%3A%22zh-hans%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1723527720&hash=2ea25454e22616619bbad7d7abeac302ca49a2bd796e27b9b13f161dba53eee2',
		// 	}),
		// );

		if (window.Telegram.WebApp) {
			// Initialize the WebApp
			window.Telegram.WebApp.ready();
			window.Telegram.WebApp.expand();
			window.Telegram.WebApp.disableVerticalSwipes();

			// 初始状态检查
			console.log(
				'window.Telegram.WebApp.isVerticalSwipesEnabled before disabling',
				window.Telegram.WebApp.isVerticalSwipesEnabled,
			);

			// 禁用垂直滑动手势
			window.Telegram.WebApp.disableVerticalSwipes();

			// 延迟检查状态
			setTimeout(() => {
				console.log(
					'window.Telegram.WebApp.isVerticalSwipesEnabled after disabling',
					window.Telegram.WebApp.isVerticalSwipesEnabled,
				);
			}, 0); // 延迟 100 毫秒

			// Get the user information
			const { initDataUnsafe, initData } = window.Telegram.WebApp;

			if (initDataUnsafe && initDataUnsafe.user && initData) {
				setTimeout(() => {
					dispatch(
						loginAction({
							telegramId: get(initDataUnsafe, 'user.id'),
							username: get(initDataUnsafe, 'user.username'),
							firstName: get(initDataUnsafe, 'user.first_name'),
							lastName: get(initDataUnsafe, 'user.last_name'),
							languageCode: get(initDataUnsafe, 'user.language_code'),
							inviteCode: get(initDataUnsafe, 'start_param', ''),
							initData,
						}),
					);
				}, 0);
			}
		}
	}, []);

	useEffect(() => {
		async function handleOnline() {
			if (navigator.onLine) {
				const results = await sendOfflinePostRequestsToServer();
			}
		}

		function handleOffline() {
			console.log('Network connection lost');
		}

		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOffline);
		};
	}, []);

	useEffect(() => {
		const preloadImages = () => {
			const images = document.querySelectorAll('img[data-src]');

			images.forEach((image) => {
				const img = new Image();
				img.src = image.dataset.src;
				img.onload = () => {
					image.src = image.dataset.src;
					image.removeAttribute('data-src');
				};
			});
		};

		document.addEventListener('DOMContentLoaded', preloadImages);

		return () => {
			document.removeEventListener('DOMContentLoaded', preloadImages);
		};
	}, []);

	useEffect(() => {
		const values = [];
		let lastValue = 0;
		for (let i = 0; i < 3; i += 1) {
			// 確保新數字比上一次的數字大
			const nextValue = Math.floor(Math.random() * (96 - lastValue) + lastValue);
			values.push(nextValue);
			lastValue = nextValue + 1; // 避免產生重複的數字
		}
		values.push(97); // 最後一個數字是 97

		let index = 0;

		const interval = setInterval(() => {
			const x = values[index];
			setProgress(x);
			index += 1;

			if (index === values.length) {
				clearInterval(interval);
			}
		}, 1000);

		return () => clearInterval(interval);
	}, []);
	// indexDB call完的api results

	useEffect(() => {
		let timeOut;
		if (telegramId && progress === 97) {
			timeOut = setTimeout(() => {
				setLoadingFinished(true);
			}, 1000);
		}
		return () => clearTimeout(timeOut);
	}, [telegramId, progress]);

	const renderContent = () => {
		const deviceType = detectDevice();
		// return <MobileOnlyPage />;
		if (deviceType === 'desktop') {
			return <MobileOnlyPage />;
		}

		if (loadingFinished) {
			if (!tutorialFinished) {
				return <Onboarding />;
			}
			return <App />;
		}
		return <LoadingPage progress={progress} />;
	};
	return <>{renderContent()}</>;
}

export default AppContainer;
