/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import Grid from '@metacrm/metacrm-material-ui/dist/Grid';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import axios from 'axios';
import { find, get, isEmpty } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';

import sendOfflinePostRequestsToServer, {
	clearIndexedDB,
	getAllRecords,
} from './utils/apiRecallFromDB';

import { postAttack } from 'SRC/api/game';
import {
	StyledApp,
	StyledRoutesContainer,
	StyledRouterButtons,
	StyledRouterButton,
} from 'SRC/App.styles';
import { eyeCoin } from 'SRC/assets/images';
import { StyledDialog, StyledDialogBtn } from 'SRC/Dialog.styles';
import Routes from 'SRC/Routes';
import SetIntervalComponent from 'SRC/SetIntervalComponent';
import {
	handleAddOfflineAllyAction,
	setReferral,
	setRedDotAction,
} from 'SRC/store/game/game.reducer';
import {
	selectOfflineAllyDamage,
	selectGameMonsterRemainedHealth,
	selectReferral,
	selectRecruitDot,
	selectEarnRedDot,
} from 'SRC/store/game/game.selector';
import { formatNumber } from 'SRC/utils/common';
import { getStorage } from 'SRC/utils/storage';

// const routes = [
// 	{ name: 'profile', to: '/profile' },
// 	{ name: 'earn', to: '/earn' },
// 	{ name: 'fight', to: '/' },
// 	{ name: 'recruit', to: '/recruit' },
// 	{ name: 'ranking', disabled: true },
// ];

function App() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const monsterRemainedHealth = useSelector(selectGameMonsterRemainedHealth);

	const offlineAllyDamage = useSelector(selectOfflineAllyDamage);

	const earnRedDot = useSelector(selectEarnRedDot);
	const recruitDot = useSelector(selectRecruitDot);

	const routes = [
		{ name: 'profile', to: '/profile', redDot: false },
		{ name: 'earn', to: '/earn', redDot: earnRedDot },
		{ name: 'fight', to: '/', redDot: false },
		{ name: 'recruit', to: '/recruit', redDot: recruitDot },
		{ name: 'ranking', disabled: true, redDot: false },
	];

	const referral = useSelector(selectReferral);

	useEffect(() => {
		const initData = window.Telegram.WebApp.initDataUnsafe;

		if (initData && initData.start_param && initData.start_param === 'earn') {
			navigate('earn');
		}

		if (initData && initData.start_param && initData.start_param === 'fight') {
			navigate('/');
		}
	}, []);

	const handleNavigateTo = (route) => {
		dispatch(setRedDotAction(route.name));
		navigate(route.to)
	};

	return (
		<>
			{!offlineAllyDamage && <SetIntervalComponent />}

			<StyledApp>
				<Box />
				<StyledRoutesContainer>
					<Routes />
				</StyledRoutesContainer>
				<StyledRouterButtons>
					<Box component={Grid} container spacing={0} sx={{ maxWidth: '95%', margin: '0 auto' }}>
						{routes.map((route) => (
							<Grid item xs={12 / 5} key={route.name}>
								<StyledRouterButton
									active={location.pathname === route.to}
									routeName={route.name}
									onClick={() => handleNavigateTo(route)}
									disabled={route.disabled}
									redDot={route.redDot}
								/>
							</Grid>
						))}
					</Box>
				</StyledRouterButtons>
			</StyledApp>

			{/* 有離線攻擊傷害 並且怪物血量是大於0 才有離線攻擊的彈窗跳出可以claim */}
			<StyledDialog
				open={!!offlineAllyDamage && monsterRemainedHealth > 0}
				className='test'
				show={offlineAllyDamage}
			>
				<Box display='flex' flexDirection='column' alignItems='center'>
					<Box display='flex' alignItems='center' mb='35px'>
						<Box
							component='img'
							src={require(`SRC/assets/images/coin_group.png`)}
							width={125}
							height={74}
							mr='10px'
						/>
						<Box
							fontSize='36px'
							className='strokeText'
							data-storke={`${formatNumber(offlineAllyDamage)}`}
						>
							{`${formatNumber(offlineAllyDamage)}`}
						</Box>
					</Box>
					<Box color='#FFFFFF' textAlign='center'>
						<Box className='strokeText' data-storke='Welcome Back!'>
							Welcome Back!
						</Box>
						<Box
							className='strokeText'
							sx={{ whiteSpace: 'nowrap' }}
							data-storke='Your Ally has started working for you'
						>
							Your Ally has started working for you
						</Box>
					</Box>

					<Box mt='54px'>
						<StyledDialogBtn
							className='strokeText'
							data-storke='Claim'
							onClick={() => {
								dispatch(handleAddOfflineAllyAction());
							}}
						>
							Claim
						</StyledDialogBtn>
					</Box>
				</Box>
			</StyledDialog>

			{/* 被邀請人首次登入 popup				 */}
			<StyledDialog open={!isEmpty(referral)} className='test' show={!isEmpty(referral)}>
				<Box display='flex' flexDirection='column' alignItems='center'>
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Box
							component='img'
							src={require(`SRC/assets/images/coin_group.png`)}
							width={125}
							height={74}
							mr='10px'
						/>
						<Box fontSize='36px' className='strokeText' data-storke={formatNumber(referral?.bonus)}>
							{formatNumber(referral?.bonus)}
						</Box>
					</Box>

					<Box
						sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}
						className='strokeText'
						data-storke='Welcome to the Legion!'
						fontSize='24px'
					>
						Welcome to the Legion!
					</Box>

					<Box sx={{ textAlign: 'center' }} className='strokeText'>
						You&#39;ve received an invitation reward from your recruiter!
					</Box>
					<Box mt='54px'>
						<StyledDialogBtn
							className='strokeText'
							data-storke='Done'
							onClick={() => {
								dispatch(setReferral());
							}}
						>
							Done
						</StyledDialogBtn>
					</Box>
				</Box>
			</StyledDialog>

			{/* <CustomThemeProvider>
				<div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
					<Game width={dimensions.width} height={dimensions.height} />
				</div>
			</CustomThemeProvider> */}
		</>
	);
}

export default App;
