/* eslint-disable no-unreachable */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import * as Sentry from '@sentry/react';
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';
import { debounce, isEmpty } from 'lodash-es';
import Phaser from 'phaser';
import { useSelector, useDispatch } from 'react-redux';

import { useErrorPopup } from 'SRC/components/ErrorDialog/ErrorDialogProvider';
import LoadingComponent from 'SRC/components/LoadingComponent/LoadingComponent';
import { attackMonsterAction, postMonsterKillAction } from 'SRC/store/game/game.reducer';
import {
	selectGameATK,
	selectGameMonsterRemainedHealth,
	selectGameMonsterTotalBlood,
	selectCurrentMonster,
	selectNextMonster,
	selectAllyValue,
	selectGameEnergy,
	selectOfflineAllyDamage,
	selectGameMonsterList,
	selectLastMonster,
	selectChangeMonsterIng,
} from 'SRC/store/game/game.selector';

function Game() {
	const gameRef = useRef(null);
	const gameInstance = useRef(null);
	const ATK = useSelector(selectGameATK);
	const ATKRef = useRef(null);
	const monsterTotalBlood = useSelector(selectGameMonsterTotalBlood);
	const monsterTotalBloodRef = useRef(null);
	const monsterRemainedHealth = useSelector(selectGameMonsterRemainedHealth);
	const monsterRemainedHealthRef = useRef(null);
	const currentMonster = useSelector(selectCurrentMonster);
	const currentMonsterRef = useRef(null);
	const [restart, setRestart] = useState(false);
	const allyValue = useSelector(selectAllyValue);
	const allyValueRef = useRef(null);
	const offlineAllyDamage = useSelector(selectOfflineAllyDamage);
	const offlineAllyDamageRef = useRef(offlineAllyDamage);
	const currentEnergy = useSelector(selectGameEnergy);
	const currentEnergyRef = useRef(null);
	const errorHandler = useErrorPopup();
	const monsterList = useSelector(selectGameMonsterList);
	const monsterListRef = useRef(null);
	const nextMonster = useSelector(selectNextMonster);
	const nextMonsterRef = useRef(null);
	const lastMonster = useSelector(selectLastMonster);
	const changeMonsterIng = useSelector(selectChangeMonsterIng);
	const [waitForGameFinished, setWaitForGameFinished] = useState(false);
	const [gameInitialized, setGameInitialized] = useState(false);

	const dispatch = useDispatch();
	// const width = 375;
	// const height = 200;
	const [impactOccurred, notificationOccurred, selectionChanged] = useHapticFeedback();

	const resizeGame = (game) => {
		const container = document.getElementById('game-container');
		if (container && gameInstance.current) {
			//  強制刷新瀏覽器佈局，確保高度正確
			container.style.height = '100%';
			container.style.width = '100%';
			const { width, height } = container.getBoundingClientRect();
			gameInstance.current.scale.resize(width, height);
			gameInstance.current.canvas.style.width = `${width}px`;
			gameInstance.current.canvas.style.height = `${height}px`;

			// 確保畫布的實際尺寸與樣式尺寸一致
			gameInstance.current.scale.displaySize.setAspectRatio(width / height);
			gameInstance.current.scale.refresh();

			// 重新啟動當前場景以確保內容刷新
			if (gameInstance.current.scene.scenes.length > 0) {
				const scene = gameInstance.current.scene.scenes[0];
				if (scene.sound) {
					scene.sound.stopAll(); // 停止场景中所有的声音
				}

				scene.scene.restart();
			}
		}
	};

	const initializeGame = () => {
		setGameInitialized(true);
		let backgroundPlaceholder;
		let background;
		let monster;
		let healthCircle;
		let monsterSaved;
		let allyDamageEvent;
		let allyDamageEventActive = false;
		let monsterCurrentHealth;
		let plane;
		const turning = false;
		let lastCollisionTime = 0;
		let thankYou;
		let clickText;
		let circleGraphic;
		let isKilling = false;
		const touchingPointers = {};
		let backgroundSound;
		let saveSound;

		function preload() {
			allyDamageEventActive = false;
			this.load.image('background', currentMonsterRef?.current?.background);
			this.load.image('backgroundPlaceholder', currentMonsterRef?.current?.background);
			this.load.image('monster', currentMonsterRef?.current?.imagesBefore);
			this.load.image('monsterSaved', currentMonsterRef?.current?.imagesAfter);
			this.load.image('thankYou', require('SRC/assets/images/thank_you.png'));
			this.load.image('plane', require('SRC/assets/images/ally.png')); // 加载飞机图像
			this.load.audio('attack', require('SRC/assets/audios/bomb.mp3'));
			this.load.audio('saved', require('SRC/assets/audios/meme_saved.mp3'));
			this.load.audio('backgroundSound', currentMonsterRef?.current?.bgm);

			// Load the explosion sprite sheet
			this.load.spritesheet('explosion', require('SRC/assets/images/exp4.png'), {
				frameWidth: 85.33,
				frameHeight: 85.33,
				endFrame: 9,
			});

			const onComplete = () => {
				setWaitForGameFinished(true);

				this.load.off('complete', onComplete);
			};

			this.load.on('complete', onComplete);
		}

		function handleAllyDamageEvent() {
			if (!allyDamageEventActive) {
				// eslint-disable-next-line react/no-this-in-sfc
				allyDamageEvent = this.time.addEvent({
					delay: 1000,
					callback: () => {
						if (monsterRemainedHealthRef.current > 0 && allyValueRef.current) {
							// eslint-disable-next-line no-use-before-define
							createAllyDamageText.call(this);
						}
					},
					loop: true,
				});
				allyDamageEventActive = true;
			}
		}

		function create() {
			backgroundPlaceholder = this.add.sprite(0, 0, 'backgroundPlaceholder');
			backgroundPlaceholder.setOrigin(0, 0);
			backgroundPlaceholder.setDisplaySize(this.scale.width, this.scale.height);
			backgroundPlaceholder.setDepth(4);

			background = this.add.sprite(0, 0, 'background');
			background.setOrigin(0, 0);
			background.setDisplaySize(this.scale.width, this.scale.height);
			background.setDepth(4);

			circleGraphic = this.add.graphics();
			circleGraphic.fillStyle(0x000000, 1);
			circleGraphic.fillCircle(this.scale.width / 2, this.scale.height / 2, 100);
			circleGraphic.setDepth(4);

			monster = this.add.sprite(this.scale.width / 2, this.scale.height / 2, 'monster');
			// monster.setImmovable(true);
			// 怪物圖片大小
			monster.setDisplaySize(200, 200);
			monster.setDepth(10);

			backgroundSound = this.sound.add('backgroundSound', { volume: 0.5, loop: true });

			backgroundSound.play();

			saveSound = this.sound.add('saved', { volume: 0.5 });

			thankYou = this.add.sprite(this.scale.width / 2 + 80, this.scale.height / 2 - 80, 'thankYou');
			// thankYou.setAlpha(1);
			thankYou.setDisplaySize(120, 60);
			thankYou.setDepth(11);
			thankYou.setAlpha(0);
			clickText = this.add.text(
				this.scale.width / 2,
				this.scale.height - 30,
				`Click to the next battle`,
				{
					fontSize: '20px',
					fill: '#ffffff',
					fontFamily: 'Saira',
					stroke: '#000000',
					strokeThickness: 4,
					align: 'center',
				},
			);
			clickText.setOrigin(0.5);
			clickText.setDepth(11);
			clickText.setAlpha(0);

			//
			plane = this.physics.add.image(50, 50, 'plane');
			plane.setDisplaySize(30, 30);
			plane.setDepth(100);
			plane.visible = false;

			// 註解是如果要改成只可互動點擊在monster
			// monster.setInteractive();
			// monster.on('pointerdown', hitMonster, this);

			// 目前是可互動點擊在任何地方都可以攻擊monster
			this.input.on('pointerup', handlePointerDown, this);
			// this.input.on('pointerdown', hitMonster, this);

			healthCircle = this.add.graphics();
			// updateHealthCircle();

			this.anims.create({
				key: 'explode',
				frames: this.anims.generateFrameNumbers('explosion', {
					start: 0,
					end: 15,
				}),
				frameRate: 15,
				hideOnComplete: true,
			});

			// 讓phaser canvas 可以滾動與滑動
			if (this.input.manager.touch) {
				this.input.manager.touch.capture = false;
				this.input.manager.mouse.enabled = false;
			}
			this.input.mouse.preventDefaultWheel = false;
			// handleAllyDamageEvent.call(this);
		}

		// 更新血量 update health

		function update() {
			// 更新游戏逻辑
			updateHealthCircle.call(this);

			if (!offlineAllyDamageRef.current) {
				if (monsterRemainedHealthRef.current > 0 && allyValueRef.current) {
					if (!plane.visible) {
						handleAllyDamageEvent.call(this);
						plane.visible = true;
						plane.setVelocity(100, 200);
						plane.setBounce(1, 1);
						plane.setCollideWorldBounds(true);
					}

					// 計算飛機角度;
					if (plane.body) {
						const { velocity } = plane.body;
						const angle = Math.atan2(velocity.y, velocity.x) + Math.PI / 2;
						plane.setRotation(angle);
					}

					if (Phaser.Geom.Intersects.RectangleToRectangle(plane.getBounds(), monster.getBounds())) {
						const currentTime = this.time.now;
						if (currentTime - lastCollisionTime > 200) {
							// 0.1秒的冷卻
							lastCollisionTime = currentTime;
							const offsetX = Phaser.Math.Between(-20, 20);
							const offsetY = Phaser.Math.Between(-20, 20);
							const explosion = this.add.sprite(plane.x + offsetX, plane.y + offsetY, 'explosion');
							explosion.setDepth(10);
							explosion.play('explode');
						}
					}
				} else {
					plane.visible = false;
				}
			}
		}

		function updateHealthCircle() {
			// 血條半徑
			const radius = 100;
			const healthPercentage =
				monsterRemainedHealthRef.current <= 0
					? 0
					: monsterRemainedHealthRef.current / monsterTotalBloodRef.current;

			if (healthPercentage <= 0 && !monsterSaved) {
				thankYou.setAlpha(1);
				clickText.setAlpha(1);

				// monster.setAlpha(0);
				monsterSaved = this.add.sprite(this.scale.width / 2, this.scale.height / 2, 'monsterSaved');
				monsterSaved.setDisplaySize(200, 200);
				monsterSaved.setDepth(9);

				this.time.delayedCall(200, () => {
					monster.setAlpha(0);
				});
			}
			const startAngle = Phaser.Math.DegToRad(-90);
			const endAngle = startAngle - Phaser.Math.DegToRad(360 * healthPercentage);
			healthCircle.clear();

			// arc(x, y, radius, startAngle, endAngle, anticlockwise)
			// anticlockwise true 的話是逆時針，false 的話是順時針繪製

			// Draw black background circle
			healthCircle.lineStyle(8, 0x000000, 1);
			healthCircle.beginPath();
			healthCircle.arc(
				this.scale.width / 2,
				this.scale.height / 2,
				radius,
				0,
				Phaser.Math.DegToRad(360),
				false,
			);
			healthCircle.strokePath();
			healthCircle.setDepth(5);

			// Draw red health circle
			healthCircle.lineStyle(8, 0xff0000, 1);
			healthCircle.beginPath();
			healthCircle.arc(
				this.scale.width / 2,
				this.scale.height / 2,
				radius,
				startAngle,
				endAngle,
				true,
			);
			healthCircle.strokePath();
			healthCircle.setDepth(5);
		}

		function handlePointerDown(pointer) {
			pointer.event.stopPropagation();

			if (!touchingPointers[pointer.identifier]) {
				touchingPointers[pointer.identifier] = true;

				hitMonster.call(this, pointer); // 使用 call 來明確綁定 this

				// 使用短暫的延遲來重置觸摸點狀態
				this.time.delayedCall(10, () => {
					// 使用 Object.keys 來遍歷對象的自身屬性
					Object.keys(touchingPointers).forEach((id) => {
						delete touchingPointers[id];
					});
				});
			}
		}

		async function hitMonster(pointer) {
			const isLastMonster = lastMonster._id === currentMonsterRef?.current._id;

			// 應該更換成下一隻monster
			if (monsterRemainedHealthRef.current <= 0) {
				// monsterCurrentHealth = monsterTotalBlood;

				if (isKilling) {
					return;
				}
				isKilling = true;

				const killFunction = async () => {
					saveSound.play();
					monster.setAlpha(0);
					monster.destroy();
					background.destroy();

					// thankYou.setAlpha(1);
					// clickText.setAlpha(1);
					backgroundSound.stop();
					// this.sound.remove('backgroundSound');
					backgroundSound.destroy();
					this.sound.remove('backgroundSound');
					delete this.cache.audio.entries.entries.backgroundSound; // 刪除音效緩存
					// this.load.audio('backgroundSound', require('SRC/assets/audios/monster2.mp3'));

					// this.load.once(
					// 	'complete',
					// 	() => {
					// 		backgroundSound = this.sound.add('backgroundSound', { volume: 0.5, loop: true });
					// 		backgroundSound.play(); // 播放新的音效
					// 	},
					// 	this,
					// );

					// this.load.start();

					try {
						this.tweens.add({
							targets: [monsterSaved, thankYou, clickText],
							alpha: 0,
							duration: 2000, // 整體動畫持續時間
							yoyo: false, // 不反向播放
							repeat: 0, // 不重複

							onComplete: () => {
								this.textures.remove('monster');
								this.textures.remove('background');

								this.load.image('monster', nextMonsterRef?.current?.imagesBefore);
								this.load.image('background', nextMonsterRef?.current?.background);

								this.load.once('complete', () => {
									// background.setTexture('background');
									// monster.setTexture('monster');

									background = this.add.sprite(0, 0, 'background');
									background.setOrigin(0, 0);
									background.setDisplaySize(this.scale.width, this.scale.height);
									background.setDepth(4);
									background.setAlpha(0);

									monster = this.add.sprite(this.scale.width / 2, this.scale.height / 2, 'monster');
									monster.setDisplaySize(200, 200);
									monster.setDepth(10);
									monster.setAlpha(0);

									this.tweens.add({
										targets: background,
										alpha: 1,
										duration: 2000, // Duration of the fade-in effect
										onUpdate: (tween, target) => {
											// 白光閃爍
											const intensity = Math.floor(
												255 * Math.abs(Math.sin(tween.progress * Math.PI * 10)),
											);
											const tintValue = Phaser.Display.Color.GetColor(
												intensity,
												intensity,
												intensity,
											);
											target.setTint(tintValue);
										},
										onComplete: async (tween, targets) => {
											targets.forEach((target) => {
												target.setTint(0xffffff); // 設置回默認顏色，這樣相當於清除 tint
											});

											backgroundPlaceholder.destroy();
											this.textures.remove('backgroundPlaceholder');
											this.load.image('backgroundPlaceholder', nextMonsterRef?.current?.background);
											this.load.once('filecomplete-image-backgroundPlaceholder', () => {
												backgroundPlaceholder = this.add.sprite(0, 0, 'backgroundPlaceholder');
												backgroundPlaceholder.setOrigin(0, 0);
												backgroundPlaceholder.setDisplaySize(this.scale.width, this.scale.height);
												backgroundPlaceholder.setDepth(3);
												// backgroundPlaceholder.setAlpha(0);
												//
												// backgroundPlaceholder.setTexture('backgroundPlaceholder');
												// backgroundPlaceholder.setAlpha(1);
											});

											this.load.start();
										},
									});

									this.tweens.add({
										targets: monster,
										alpha: 1,
										duration: 2000, // Duration of the fade-in effect
										onUpdate: (tween, target) => {
											// 計算紅光閃爍效果
											const tintValue = Phaser.Display.Color.GetColor(
												255,
												Math.floor(255 * Math.abs(Math.sin(tween.progress * Math.PI * 10))),
												Math.floor(255 * Math.abs(Math.sin(tween.progress * Math.PI * 10))),
											);
											target.setTint(tintValue);
										},
										onComplete: async (tween, targets) => {
											targets.forEach((target) => {
												target.setTint(0xffffff); // 設置回默認顏色，這樣相當於清除 tint
											});
											try {
												const data = await dispatch(
													postMonsterKillAction({
														monsterId: currentMonsterRef?.current?._id,
													}),
												).unwrap();

												// backgroundSound = this.sound.add('backgroundSound', {
												// 	volume: 0.5,
												// 	loop: true,
												// });
												// backgroundSound.play(); // 播放新的音效

												// backgroundSound = this.sound.add('backgroundSound', {
												// 	volume: 0.5,
												// 	loop: true,
												// });

												// backgroundSound.play();

												monsterSaved.destroy();
												this.textures.remove('monsterSaved');

												this.load.image('monsterSaved', currentMonsterRef?.current?.imagesAfter);
												this.load.audio('backgroundSound', currentMonsterRef?.current?.bgm);

												this.load.once('complete', () => {
													monsterSaved = null;

													backgroundSound = this.sound.add('backgroundSound', {
														volume: 0.5,
														loop: true,
													});
													backgroundSound.play(); // 播放新的音效

													// thankYou.setAlpha(0);
													// clickText.setAlpha(0);

													// monsterSaved = this.add.sprite(
													// 	this.scale.width / 2,
													// 	this.scale.height / 2,
													// 	'monsterSaved',
													// );
													// monsterSaved.setDisplaySize(200, 200);
													// monsterSaved.setDepth(9);
												});

												// 開始載入
												this.load.start();

												monsterSaved = null;
												isKilling = false;
											} catch (error) {
												console.error('error: ', error);
												Sentry.captureException(error);
											}
										},
									});
								});

								this.load.start();
							},
						});
					} catch (error) {
						console.error('error: ', error);
						Sentry.captureException(error);
						if (error.message !== 'Monster not found') {
							errorHandler({ errorText: 'Network Error...' }).catch(() => {});
						}
					}
				};
				await debounce(killFunction, 500)();
				// await killFunction();
			} else {
				if (
					currentEnergyRef.current - ATKRef.current < 0 ||
					monsterRemainedHealthRef.current <= 0
				) {
					return;
				}
				dispatch(attackMonsterAction({ isLastMonster }));
				// 製作爆炸效果 Create explosion animation
				for (let i = 0; i < 1; i += 1) {
					const offsetX = Phaser.Math.Between(-30, 30);
					const offsetY = Phaser.Math.Between(-30, 30);
					const explosion = this.add.sprite(pointer.x, pointer.y, 'explosion');
					explosion.setDepth(10);
					explosion.play('explode');
				}
				// 顯示傷害文字 Create damage text
				this.sound.play('attack');
				const damageText = this.add.text(pointer.x, pointer.y, `-${ATKRef.current}`, {
					fontSize: '40px',
					fill: '	#FF0000',
					stroke: '#000000',
					strokeThickness: 3,
				});
				damageText.setDepth(10);
				this.tweens.add({
					targets: damageText,
					y: pointer.y - 50,
					alpha: 0,
					duration: 1000,
					ease: 'Power1',
					onComplete: () => {
						damageText.destroy();
					},
				});

				// 傷害monster
				// monsterCurrentHealth -= ATKRef.current;

				// monster 受傷 animation
				monster.setAlpha(0.5); // Set opacity to 50%
				this.time.delayedCall(50, () => {
					monster.setAlpha(1);
				});
				// 使用觸覺反饋
				impactOccurred('medium');
			}
		}

		function createAllyDamageText() {
			const centerX = this.scale.width / 2;
			const centerY = this.scale.height / 2;
			const damageText = this.add.text(centerX, centerY, `-${allyValueRef.current}`, {
				fontSize: '40px',
				fill: ' #FF0000',
				stroke: '#000000',
				strokeThickness: 3,
			});
			damageText.setX(centerX - damageText.width / 2);
			damageText.setY(centerY - damageText.height);
			damageText.setDepth(80);
			this.tweens.add({
				targets: damageText,
				y: centerY - 150,
				alpha: 0,
				duration: 1000,
				ease: 'Power1',
				onComplete: () => {
					damageText.destroy();
				},
			});
		}

		const config = {
			type: Phaser.AUTO,
			width: '100%',
			height: '98%',
			transparent: true,
			parent: gameRef.current,
			input: {
				activePointers: 5,
			},
			physics: {
				default: 'arcade',
				arcade: {},
			},
			scene: {
				preload,
				create,
				update,
			},
			// callbacks: {
			// 	postBoot(game) {
			// 		resizeGame();
			// 	},
			// },
			scale: {
				mode: Phaser.Scale.FIT,
				autoCenter: Phaser.Scale.CENTER_BOTH,
			},
			// 使用 canvasStyle 属性设置 ID
		};

		gameInstance.current = new Phaser.Game(config);

		window.addEventListener('resize', () => {
			setTimeout(resizeGame, 0);
		});
		window.addEventListener('orientationchange', () => {
			setTimeout(resizeGame, 0);
		});
	};

	useEffect(() => {
		ATKRef.current = ATK; // 更新 ATKRef 的值
	}, [ATK]);

	useEffect(() => {
		monsterTotalBloodRef.current = monsterTotalBlood;
	}, [monsterTotalBlood]);

	useEffect(() => {
		monsterRemainedHealthRef.current = monsterRemainedHealth;
	}, [monsterRemainedHealth]);

	useEffect(() => {
		offlineAllyDamageRef.current = offlineAllyDamage;
	}, [offlineAllyDamage]);

	useEffect(() => {
		allyValueRef.current = allyValue;
	}, [allyValue]);

	useEffect(() => {
		currentEnergyRef.current = currentEnergy;
	}, [currentEnergy]);

	useEffect(() => {
		currentMonsterRef.current = null;
	}, []);

	useEffect(() => {
		if (changeMonsterIng) {
			return;
		}

		currentMonsterRef.current = currentMonster;
	}, [currentMonster, changeMonsterIng]);

	useEffect(() => {
		nextMonsterRef.current = nextMonster;
	}, [nextMonster]);

	useEffect(() => {
		if (!gameInitialized && !changeMonsterIng && currentMonsterRef.current) {
			initializeGame();
		}
	}, [changeMonsterIng, gameInitialized]);

	useEffect(
		() => () => {
			currentMonsterRef.current = null;
			if (gameInstance.current && typeof gameInstance.current.destroy === 'function') {
				gameInstance.current.destroy(true);
			}
		},
		[],
	);

	return (
		<Box height='99%' width='100%' position='relative'>
			<Box height='100%' width='100%' ref={gameRef} id='test' />
			{/* <LoadingComponent sx={{ position: 'absolute' }} /> */}

			{!waitForGameFinished && (
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						background: 'linear-gradient(180deg, #0F1221 0%, #151A2F 100%)',
					}}
				>
					<LoadingComponent width='180px' height='220px' sx={{ position: 'absolute' }} />
				</Box>
			)}
		</Box>
	);
}

export default Game;
