import { useEffect, useState } from 'react';

import Grid from '@metacrm/metacrm-material-ui/dist/Grid';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import { StyledBtn, StyledFriendsBtn, StyledRecruitBtn, StyledRecruitText } from './Recruit.styles';
import RecruitDialog from './RecruitDialog';

import { fetchRecruitStats, getCurrentReward } from 'SRC/api/recruit';
import StyledDialogBtn from 'SRC/components/Button/StyledDialogBtn';
import LoadingComponent from 'SRC/components/LoadingComponent/LoadingComponent';
import {
	setAddCoinAction,
	setReferralOpenAction,
	setRedDotAction,
} from 'SRC/store/game/game.reducer';
import { selectRankIconMapping } from 'SRC/store/game/game.selector';

export default function Recruit() {
	const rankIconMapping = useSelector(selectRankIconMapping);
	const [imagesLoaded, setImagesLoaded] = useState(0);
	const [friendImagesLoaded, setFriendImagesLoaded] = useState(null);
	const [stats, setStats] = useState({
		claimed: 0,
		friends: [],
		referredCount: 0,
		unclaimed: 0,
	});
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	const friendImagesLoadedFinished = stats.friends.length === friendImagesLoaded;

	async function getRecruitStats() {
		const data = await fetchRecruitStats();
		setStats(data);
	}

	async function getCurrentRewardHandler() {
		if (stats.unclaimed === 0) return;
		setIsLoading(true);
		try {
			const data = await getCurrentReward();
			dispatch(setAddCoinAction(parseInt(stats.unclaimed, 10)));
			setStats((prev) => ({ ...prev, claimed: data.claimed, unclaimed: 0 }));
		} catch (err) {
			console.log(err);
			Sentry.captureException(err);
		} finally {
			setIsLoading(false);
		}
	}

	useEffect(() => {
		getRecruitStats();
		dispatch(setRedDotAction('recruit'));
	}, []);

	const handleImageLoad = () => {
		setImagesLoaded((prevCount) => prevCount + 1);
	};

	const handleFriendImageLoad = () => {
		setFriendImagesLoaded((prevCount) => prevCount + 1);
	};

	return (
		<Box
			sx={{
				bgcolor: 'black',
				minHeight: '100%',
				color: 'white',
				display: 'flex',
				flexDirection: 'column',
				px: '20px',
			}}
		>
			<Box
				sx={{
					transform: imagesLoaded < 3 ? 'scaleY(1)' : 'scaleY(0)',
					transformOrigin: 'top',
					overflow: 'hidden',
					height: imagesLoaded < 3 ? '85vh' : 0,
				}}
			>
				<LoadingComponent />
			</Box>
			<Box
				sx={{
					transform: imagesLoaded < 3 ? 'scaleY(0)' : 'scaleY(1)',
					transformOrigin: 'top',
					overflow: 'hidden',
					height: imagesLoaded < 3 ? '0' : 'auto',
					transition: 'all 0.2s ease',
				}}
			>
				<Box sx={{ display: 'flex ', flexDirection: 'column', alignItems: 'center' }}>
					<Box minHeight='235px'>
						<Box sx={{ fontSize: '22px', fontWeight: 900, pt: '38px' }}>Total Rewards</Box>
						<Box
							sx={{
								position: 'fixed',
								top: '366px',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								zIndex: 0,
							}}
						>
							<img
								src={require('SRC/assets/images/treasure-bg.png')}
								alt='treasure'
								onLoad={handleImageLoad}
							/>
						</Box>
						<Box sx={{ position: 'relative', display: 'flex' }}>
							<img
								src={require('SRC/assets/images/recruit-coin-bg.png')}
								alt='coin'
								onLoad={handleImageLoad}
							/>

							<Box
								sx={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
								}}
							>
								<img
									src={require('SRC/assets/images/treasure.png')}
									alt='treasure'
									onLoad={handleImageLoad}
								/>
								<Box
									sx={{
										left: '50%',
										bottom: '-20px',
										transform: 'translateX(-50%)',
										fontSize: '36px',
										textAlign: 'center',
									}}
									className='strokeText'
									style={{ position: 'absolute' }}
									data-storke={stats.claimed}
								>
									{stats.claimed}
								</Box>
							</Box>
						</Box>
					</Box>

					<Box
						sx={{
							pb: '10px',
							fontSize: '24px',
						}}
						className='strokeText'
						data-storke='Recruit Frens to get bonus'
					>
						Recruit Frens to get bonus
					</Box>
					<StyledRecruitBtn
						sx={{ position: 'relative', height: 'fit-content', p: '18px 45px', width: '100%' }}
					>
						<Box
							sx={{
								fontSize: '20px',
								textAlign: 'center',
								pb: '6px',
							}}
							className='strokeText'
							data-storke='Recruit Frens To Get'
						>
							Recruit Frens To Get
						</Box>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								fontSize: '20px',
								pb: '12px',
							}}
						>
							<Box className='strokeText' data-storke='50M'>
								50M
							</Box>
							<Box
								component='img'
								sx={{
									width: '20px',
									height: '20px',
								}}
								src={require('SRC/assets/images/coin1.png')}
							/>
							<Box className='strokeText' data-storke='Total Rewards'>
								Total Rewards
							</Box>
						</Box>

						<Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '30px' }}>
							<Box sx={{ flex: 1 }}>
								<Box
									sx={{
										fontSize: '14px',
										fontWeight: 500,
										color: '#0F1221',
										display: 'flex',
										alignItems: 'center',
										py: '8px',
									}}
								>
									FRENS: {stats.referredCount} / 100
								</Box>
								<StyledDialogBtn onClick={() => dispatch(setReferralOpenAction(true))}>
									Recruit
								</StyledDialogBtn>
							</Box>
							<Box sx={{ flex: 1 }}>
								<Box
									sx={{
										height: '30px',
										fontSize: '14px',
										fontWeight: 500,
										color: '#0F1221',
										display: 'flex',
										alignItems: 'center',
										py: '8px',
									}}
								>
									CLAIMABLE: {stats.unclaimed}
									<Box
										component='img'
										sx={{
											width: '20px',
											height: '20px',
										}}
										src={require('SRC/assets/images/coin1.png')}
									/>
								</Box>
								<StyledDialogBtn
									onClick={() => getCurrentRewardHandler()}
									isLoading={isLoading || stats.unclaimed === 0}
								>
									Claim
								</StyledDialogBtn>
							</Box>
						</Box>
					</StyledRecruitBtn>
				</Box>

				<Box
					sx={{
						height: friendImagesLoadedFinished ? 'auto' : '0px',
						overflow: 'hidden',
						transition: 'all 0.2s ease',
						transform: friendImagesLoadedFinished ? 'scaleY(1)' : 'scaleY(0)',
						transformOrigin: 'top',
					}}
				>
					<Box
						sx={{
							fontSize: '24px',
							fontWeight: 600,
							textAlign: 'center',
							m: '20px 0 10px',
						}}
						className='strokeText'
						data-storke={`All Frens (${stats.friends.length})`}
					>
						All Frens ({stats.friends.length})
					</Box>
					<Box
						sx={{
							flex: 1,
							textAlign: 'center',
							overflow: 'auto',
							pb: '65px',
						}}
					>
						{stats.friends.map((friend) => (
							<StyledFriendsBtn sx={{ position: 'relative' }} key={friend._id}>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Box
										component='img'
										sx={{
											width: '40px',
											height: '40px',
											borderRadius: '50%',
											overflow: 'hidden',
											border: ' 2px solid #000',
										}}
										src={friend.profileImage}
										onLoad={handleFriendImageLoad}
									/>
									<Box ml='18px'>
										<Box
											sx={{
												fontSize: '20px',
												fontWeight: 600,
											}}
											className='strokeText'
											data-storke={
												friend?.username || (friend?.lastName || '') + (friend?.firstName || '')
											}
										>
											{friend?.username || (friend?.lastName || '') + (friend?.firstName || '')}
										</Box>
										<Box display='flex' alignItems='center' pt='4px'>
											<Box
												sx={{
													color: '#FDAB00',
													fontSize: '16px',
													textAlign: 'left',
												}}
												className='strokeText'
												data-storke={friend.coins}
											>
												{friend.coins}
											</Box>
											<Box
												component='img'
												sx={{
													width: '20px',
													height: '20px',
												}}
												src={require('SRC/assets/images/coin1.png')}
											/>
										</Box>
									</Box>
								</Box>
								<Box display='flex' flexDirection='column' alignItems='flex-end'>
									<Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
										<Box
											component='img'
											sx={{
												width: '20px',
												height: '20px',
												borderRadius: '50%',
											}}
											src={rankIconMapping[friend.level]}
										/>
										<Box
											sx={{
												width: '1px',
												height: '14px',
												backgroundColor: '#0F1221',
											}}
										/>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												fontSize: '14px',
												color: '#0F1221',
												fontWeight: 500,
											}}
										>
											LV {friend.level}
										</Box>
									</Box>
									<Box display='flex' alignItems='center' justifyContent='flex-end' gap='10px'>
										{/* <Box
									component='img'
									sx={{
										width: '20px',
										height: '20px',
										borderRadius: '50%',
									}}
									src={require('SRC/assets/images/bear.png')}
								/> */}
										{/* <Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										fontSize: '14px',
										color: '#0F1221',
										fontWeight: 500,
									}}
								>
									Alien Club
								</Box> */}
									</Box>
								</Box>
							</StyledFriendsBtn>
						))}
					</Box>
				</Box>

				<RecruitDialog />
			</Box>
		</Box>
	);
}
