/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import TutorialPage from './TutorialPage';
import VideoPage from './VideoPage';

function Onboarding() {
	const [step, setStep] = useState(1);

	const handleNextStep = () => {
		setStep((prev) => prev + 1);
	};

	return (
		<>
			{step === 1 && <VideoPage onNext={handleNextStep} />}
			{step === 2 && <TutorialPage />}
		</>
	);
}

export default Onboarding;
