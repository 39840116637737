/* eslint-disable indent */
// import crypto from 'crypto';

import CryptoJS from 'crypto-js';

import configs from 'SRC/config';

const secretKey = CryptoJS.enc.Utf8.parse(configs.secretKey);

export function generateCustomNonce(
	length = 16,
	charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
) {
	let nonce = '';
	const charsetLength = charset.length;

	for (let i = 0; i < length; i += 1) {
		const randomIndex = Math.floor(Math.random() * charsetLength);
		nonce += charset.charAt(randomIndex);
	}

	return nonce;
}

export function generateHMAC({ nonce, data, timestamp }) {
	const newData = data ? JSON.stringify(data) : '';
	const newTimestamp = JSON.stringify(timestamp);
	const msg = newData + nonce + newTimestamp;
	const hmac = CryptoJS.HmacSHA256(msg, secretKey); // 生成 HMAC
	const hmacString = hmac.toString(CryptoJS.enc.Hex);

	return hmacString;
}
