const configs = {
	dev: {
		apiUrl: 'https://legion-dev.unayung.cc/',
		botUrl: 'https://t.me/clifford0725555_bot/start?startapp=',
		secretKey: 'metacrmisawesome',
	},
	demo: {
		apiUrl: 'https://legion.unayung.cc/',
		botUrl: 'https://t.me/clifford0725555_bot/start?startapp=',
		secretKey: 'a1b2c3d4e5f67890abcdef1234567890abcdef1234567890abcdef1234567890',
	},
	production: {
		apiUrl: 'https://api.legiongames.today/',
		botUrl: 'https://t.me/legion_meme_world_bot/start?startapp=',
		secretKey: 'a1b2c3d4e5f67890abcdef1234567890abcdef1234567890abcdef1234567890',
	},
};

const environment = process.env.REACT_APP_ENV || 'dev';

export default configs[environment];
