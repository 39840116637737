const blackListProps = {
	blockColor: true,
	routeName: true,
	monsterName: true,
	monsterBackground: true,
	isLoading: true,
	noMoney: true,
	isGet: true,
	bgColor: true,
	btnColor: true,
	redDot: true,
};

export default blackListProps;
